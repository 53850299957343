import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ShopContextProvider } from "./context/ShopContext";
import { ProductContextProvider } from "./context/ProductContext";
import { CategoryContextProvider } from "./context/CategoryContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ShopContextProvider>
    <ProductContextProvider>
      <CategoryContextProvider>
        <App />
      </CategoryContextProvider>
    </ProductContextProvider>
  </ShopContextProvider>
  // </React.StrictMode>
);
