import React, { useState, useContext } from "react";
// import image1 from '../../images/_MG_6920.jpg'
// import image2 from '../../images/_MG_6921.jpg'
// import image3 from '../../images/_MG_6922.jpg'
// import image4 from '../../images/_MG_6923.jpg'
// import img1 from '../../../public/Laxman_6KHQ/_MG_6921.jpg'
import PRODUCTS from "../../Products";
// import ProductItem from '../../ProductItems'
import styles from "./LightBox.module.css";

const Lightbox = ({ product }) => {
  // const imageArray = props.imgArray;
  const [mainDiv, setmainDiv] = useState(product?.images[0]);
  // const imageStyles = {
  //     borderRadius: "10px",
  //     backgroundPosition: 'center',
  //     backgroundSize: 'contain',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundImage: `url(${product.imgArray[1]})`,
  // }
  return (
    <>
      <div className={styles.ImageDiv}>
        <img
          src={`data:image/png;base64,${mainDiv?.data}`}
          alt='mainDiv'
          key={product?.images[0]}
        />
        <div className={styles.smallImages}>
          {product?.images?.map((img, index) => {
            return (
              <img
                src={`data:image/png;base64,${img?.data}`}
                alt='mainDiv'
                className={mainDiv === img ? styles.active : styles.smallimg}
                onClick={() => setmainDiv(img)}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
{
  /* <div className={styles.imageDive} style={imageStyles}></div> */
}

export default Lightbox;
