import React, { useEffect, useState } from "react";
import styles from "../../components/Home/AllProducts.module.css";
import Card from "../../components/CardAltered/Card";
import Cart from "../../components/CartAltered/Cart";
import component1 from "../../images/Component5.png";
import component2 from "../../images/Component8.png";
import { Loader } from "@mantine/core";
import { searchProduct } from "../../api/API_GET";
import { useLocation } from "react-router-dom";

export default function SearchedProduct() {
  const [searchInput, setSearchInput] = useState(
    window.location.pathname.split("/")[2]
  );
  const [showCart, setShowCart] = useState(false);
  const [records, setRecords] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });

  const location = useLocation();

  useEffect(() => {
    let locationString = location.pathname.split("/")[2];
    if (locationString === "") {
      locationString = "name";
    }
    setSearchInput(locationString);
  }, [location.pathname]);

  useEffect(() => {
    const fetchApi = async () => {
      await searchProduct(searchInput, setRecords, setLoading);
    };
    fetchApi();
  }, [searchInput]);

  useEffect(() => {
    console.log(records);
    setProducts(records);
  }, [records]);

  return (
    <div className={styles.AllProductsSearch}>
      <div className='headerAll hiddenHeading'>
        <img
          className={styles.component1}
          src={component1}
          alt='commponnet'
        />
        <h1>Search Result</h1>
        <img
          className={styles.component1}
          src={component2}
          alt='commponnet'
        />
      </div>

      {!loading ? (
        products.length > 0 ? (
          <>
            <div
              className={
                showCart ? `${styles.model} ${styles.open}` : styles.model
              }
            >
              <div className={styles.SideCart}>
                <Cart setShowCart={setShowCart} />
              </div>
            </div>
            <div className={styles.OuterSection}>
              <div className={styles.container}>
                {products.slice(0, 6).map((item, index) => {
                  return (
                    <div
                      className={styles.InnerProducts}
                      key={index}
                    >
                      <Card
                        item={item}
                        setShowCart={setShowCart}
                        key={index}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <h2 style={{ marginTop: "10px" }}>No Products Found!</h2>
        )
      ) : (
        <div
          className={styles.loading}
          style={{ marginTop: "10px" }}
        >
          <Loader color='#7E8865' /> <br />
        </div>
      )}
    </div>
  );
}
