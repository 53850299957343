import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your components here
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar";
import About from "./pages/About/About";
import Footer from "./components/Footer";
import Shop from "./pages/Shop/Shop";
import Contact from "./pages/Contact/Contact";
import Checkout from "./pages/Checkout/Checkout";
import Product from "./pages/Product/Product";
import CartPage from "./pages/CartPage/CartPage";
import ScrollToTop from "./pages/ScrollToTop/ScrollToTop";
import SideBar from "./AdminTemplate/components/SideBar";
import TopHeader from "./AdminTemplate/components/TopHeader";
import AdminHome from "./AdminTemplate/components/Home";
import AdminCategory from "./AdminTemplate/components/Category";
import AdminProduct from "./AdminTemplate/components/Products/Products";
import AdminOrder from "./AdminTemplate/components/Order";
import AdminAddProduct from "./AdminTemplate/components/Products/AddProduct";
import AdminEditProduct from "./AdminTemplate/components/Products/Update_product";
import AdminAccessDenied from "./AdminTemplate/components/AccessDenied/AccessDenied";
import AdminLogin from "./AdminTemplate/components/LoginPage";
import NewArrivals from "./AdminTemplate/components/NewArrivals";
import Banner from "./AdminTemplate/components/Banner";
import AddBanner from "./AdminTemplate/components/Banner/AddBanner";
import PageNotFound from "./components/PageNotFound";
import SearchedProduct from "./pages/SearchProduct/SearchedProduct";

const ProtectedRoute = ({ element }) => {
  if (document.cookie.split("=")[1] === undefined) {
    window.location.href = "/dashboard/Login";
    return null; // Return null to prevent rendering the protected content
  }

  return (
    <>
      <TopHeader />
      <SideBar />
      {element}
    </>
  );
};

const App = () => {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          {/* Public Routes */}
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/about'
            element={<About />}
          />
          <Route
            path='/shop'
            element={<Shop />}
          />
          <Route
            path='/contact'
            element={<Contact />}
          />
          <Route
            path='/checkout101'
            element={<Checkout />}
          />
          <Route
            path='/cart'
            element={<CartPage />}
          />
          <Route
            path='/product/:id'
            element={<Product />}
          />

          <Route
            path='/search/:keyword'
            element={<SearchedProduct />}
          />

          {/* Admin Routes */}
          <Route
            path='/dashboard/Login'
            element={<AdminLogin />}
          />
          <Route
            path='/dashboard/AccessDenied'
            element={<AdminAccessDenied />}
          />
          <Route
            path='/dashboard/'
            element={<ProtectedRoute element={<AdminHome />} />}
          />
          <Route
            path='/dashboard/Category'
            element={<ProtectedRoute element={<AdminCategory />} />}
          />
          <Route
            path='/dashboard/Products'
            element={<ProtectedRoute element={<AdminProduct />} />}
          />
          <Route
            path='/dashboard/add_product'
            element={<ProtectedRoute element={<AdminAddProduct />} />}
          />
          <Route
            path='/dashboard/edit_product/:id'
            element={<ProtectedRoute element={<AdminEditProduct />} />}
          />
          <Route
            path='/dashboard/Order'
            element={<ProtectedRoute element={<AdminOrder />} />}
          />
          <Route
            path='/dashboard/banner'
            element={<ProtectedRoute element={<Banner />} />}
          />
          <Route
            path='/dashboard/add_banner'
            element={<ProtectedRoute element={<AddBanner />} />}
          />
          <Route
            path='/dashboard/new_arrivals'
            element={<ProtectedRoute element={<NewArrivals />} />}
          />

          <Route
            path='*'
            element={<PageNotFound />}
          />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
