import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IconContext } from "react-icons";
import styles from "./Navbar.module.css";
import Cart from "./CartAltered/Cart";
import { ShopContext } from "../context/ShopContext";
import { searchProduct } from "../api/API_GET";
import SearchedProduct from "../pages/SearchProduct/SearchedProduct";

const Navbar = () => {
  const { cartItems } = useContext(ShopContext);
  const [CartItemNumber, setCartItemNumber] = useState(0);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const windowWidth = window.innerWidth;
  console.log(windowWidth);
  useEffect(() => {
    setCartItemNumber(cartItems.length);
  }, [cartItems]);

  const location = useLocation();

  useEffect(() => {
    let locationString = location.pathname.slice(1);
    if (!locationString) {
      locationString = "home";
    }
    const str2 =
      locationString.charAt(0).toUpperCase() + locationString.slice(1);
    // const str2 = locationString.toUpperCase();
    let titleString = "Grovy Handmade - " + str2;
    document.title = titleString;
  }, [location.pathname]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownwho, setDropdownwho] = useState(false);

  const handleDropdownCclick = e => {
    setDropdownVisible(!dropdownVisible);
    if (dropdownVisible) {
      e.target.classList.add(styles.color);
    } else {
      e.target.classList.remove(styles.color);
    }
  };

  const handleClickWho = e => {
    setDropdownwho(!dropdownwho);
    if (dropdownwho) {
      e.target.classList.add(styles.color);
    } else {
      e.target.classList.remove(styles.color);
    }
  };
  let locationString = location.pathname.slice(1);
  const shouldShowNavbar = locationString.includes("dashboard");

  // const [NumberofCartItem, setNumberofCartItem] = useState(0);
  // const [storedKey, setStoredKey] = useState([]);

  // useEffect(() => {
  //     const storedKeysString = localStorage.getItem('cartKey');
  //     setStoredKey(storedKeysString ? JSON.parse(storedKeysString) : [])
  // }, [])

  // useEffect(() => {
  //     setNumberofCartItem(storedKey.length);
  // }, [storedKey])

  // const [storedKey, setStoredKey] = useState([]);

  // useEffect(() => {
  //     // console.log('storedKey effect triggered');
  //     const storedKeysString = localStorage.getItem('cartKey');
  //     const parsedStoredKeys = storedKeysString ? JSON.parse(storedKeysString) : [];
  //     // console.log('parsedStoredKeys:', parsedStoredKeys);
  //     setStoredKey(parsedStoredKeys);
  // }, []);

  // useEffect(() => {
  //     // console.log('NumberofCartItem effect triggered');
  //     // console.log('storedKey length:', storedKey.length);
  //     setNumberofCartItem(storedKey.length);
  // }, [storedKey]);

  const [showSearch, setshowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSearch = () => {
    if (windowWidth > 1050) {
      setshowSearch(!showSearch);
    } else {
      setSidebar(true);
    }
  };

  const [showCart, setShowCart] = useState(false);
  const handleCartClick = () => {
    setShowCart(true);
  };

  useEffect(() => {
    console.log(records);
  }, [records]);

  return (
    <>
      <div
        className={showCart ? `${styles.model} ${styles.open}` : styles.model}
      >
        <div className={styles.SideCart}>
          <Cart setShowCart={setShowCart} />
        </div>
      </div>
      {!shouldShowNavbar && (
        <header>
          <nav className={`${styles.topmenu} ${styles.fixed}`}>
            <div className={styles.navbar}>
              <div>
                <IconContext.Provider
                  value={{ color: "#03383c", size: "20px" }}
                >
                  <Link
                    to='#'
                    className={styles.menubars}
                  >
                    <FaBars onClick={showSidebar} />
                  </Link>
                </IconContext.Provider>
              </div>
              <div className={styles.logo}>
                {/* <NavLink to="/" ><img src={Logo} alt="logo" /></NavLink> */}
                <NavLink to='/'>
                  <p>Groovy</p>
                </NavLink>
              </div>
              <div className={styles.text}>
                <NavLink
                  to='/'
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles.navitem
                      : isActive
                      ? styles.navactive
                      : styles.navitem
                  }
                >
                  Home
                </NavLink>
                <NavLink
                  to='/about'
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles.navitem
                      : isActive
                      ? styles.navactive
                      : styles.navitem
                  }
                >
                  About
                </NavLink>
                <NavLink
                  to='./shop'
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles.navitem
                      : isActive
                      ? styles.navactive
                      : styles.navitem
                  }
                >
                  Shop
                </NavLink>

                {/* <div className={styles.dropdownContainer}>
                                <Link to="/shop" className={styles.navitem}>Shop</Link>
                                <div className={styles.dropdownContent}>
                                    <Link to="/services/OfficeCleaning" className={styles.dropdownItem}>DropDown 1</Link>
                                    <Link to="/services/Housecleaning" className={styles.dropdownItem}>DropDown 2</Link>
                                </div>
                            </div> */}
                <NavLink
                  to='contact'
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles.navitem
                      : isActive
                      ? styles.navactive
                      : styles.navitem
                  }
                >
                  Contact
                </NavLink>
                {/* <Link to="/contact" className={styles.navitem}>Contact US</Link> */}
                {/* <Link to="/bookNow" className={`${styles.navitem} `} id={styles.button}>Book Now
                                <div className='button'>Book Now</div>
                            </Link> */}
                {/* <div type="button" className="btn position-relative"> */}
              </div>

              <div className={styles.Icons}>
                <IconContext.Provider
                  value={{ color: "#03383c", size: "20px" }}
                >
                  {/* <Link to="/" className={styles.navitem} style={{ position: 'relative' }} ><FaIcons.FaRegHeart />
                                        <span className="position-absolute  translate-middle badge rounded-pill" style={{ backgroundColor: "#7e8865", color: '#d9dfd7', fontSize: '8px', top: '13%', right: '-80%' }}>
                                            0
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    </Link> */}
                  <Link
                    to='#'
                    className={styles.navitem}
                    onClick={() => handleSearch()}
                  >
                    <FiSearch />
                  </Link>
                  <div
                    className={
                      showSearch
                        ? `${styles.SearchActive} ${styles.SearchDiv}`
                        : styles.SearchDiv
                    }
                  >
                    <div className={styles.searchInput}>
                      <input
                        type='text'
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        placeholder='Search...'
                      ></input>
                    </div>
                    <div className={styles.button}>
                      <IconContext.Provider
                        value={{ color: "white", size: "20px" }}
                      >
                        <Link
                          to={
                            searchInput
                              ? `/search/${searchInput}`
                              : "/search/name"
                          }
                          className={styles.navitem}
                          onClick={() => handleSearch()}
                        >
                          <button>
                            <FiSearch />
                          </button>
                        </Link>
                      </IconContext.Provider>
                    </div>
                  </div>
                  <Link
                    onClick={handleCartClick}
                    className={styles.navitem}
                    style={{ position: "relative" }}
                  >
                    <AiOutlineShoppingCart />
                    <span
                      className='position-absolute  translate-middle badge rounded-pill'
                      style={{
                        backgroundColor: "#7e8865",
                        color: "#d9dfd7",
                        fontSize: "8px",
                        top: "13%",
                        right: "-80%",
                      }}
                    >
                      {CartItemNumber}
                      <span className='visually-hidden'>unread messages</span>
                    </span>
                  </Link>
                </IconContext.Provider>
              </div>
            </div>
          </nav>
          <nav
            className={`${
              sidebar ? `${styles.navmenu} ${styles.active}` : styles.navmenu
            }`}
          >
            <ul className={styles.navMenuItems}>
              <li className={styles.navbarToggle}>
                <IconContext.Provider value={{ color: "#03383c" }}>
                  <Link
                    to='#'
                    className={styles.menubars}
                    onClick={showSidebar}
                  >
                    <AiOutlineClose />
                  </Link>
                </IconContext.Provider>
              </li>
              <li>
                <div className={`${styles.searchNavInput} ${styles.listitem}`}>
                  <input
                    type='text'
                    value={searchInput}
                    onChange={e => setSearchInput(e.target.value)}
                    placeholder='Search...'
                  ></input>

                  <div className={styles.buttonNav}>
                    <IconContext.Provider
                      value={{ color: "#7e8865", size: "20px" }}
                    >
                      <Link
                        to={
                          searchInput
                            ? `/search/${searchInput}`
                            : "/search/name"
                        }
                        className={styles.navitem}
                        onClick={() => {
                          handleSearch();
                          setSidebar(false);
                        }}
                      >
                        <button>
                          <FiSearch />
                        </button>
                      </Link>
                    </IconContext.Provider>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className={styles.listitem}
                  onClick={handleClickWho}
                >
                  <Link
                    to='/'
                    onClick={() => setSidebar(false)}
                  >
                    <span>Home </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  className={styles.listitem}
                  onClick={handleDropdownCclick}
                >
                  <Link
                    to='/shop'
                    onClick={() => setSidebar(false)}
                  >
                    <span>Shop </span>
                  </Link>
                </div>
                {/* <div className={`${styles.dropdownCContent} ${dropdownVisible ? styles.Aactive : ''}`}>
                                    <ul>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>One-Off Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/Regular Cleaning Services'>Regular Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/Additional Cleaning Services'>Additional Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>Anti Viral And Disinfectant Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>End of Lease Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>Office Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>Steam/Carpet Cleaning Services</Link></li>
                                        <li className={styles.droplistitem}><Link to='/One-Off Cleaning Services'>Glass Cleaning Services</Link></li>
                                    </ul>
                                </div> */}
              </li>
              <li>
                <div className={styles.listitem}>
                  <Link
                    to='/about'
                    onClick={() => setSidebar(false)}
                  >
                    <span>About </span>
                  </Link>
                </div>
              </li>
              <li>
                <div className={styles.listitem}>
                  <Link
                    to='/contact'
                    onClick={() => setSidebar(false)}
                  >
                    <span>Contact US</span>
                  </Link>
                </div>
              </li>

              {/* {SidebarDate.map((item, index) => {
                                return (
                                    <li key={index} className={styles.navText}>
                                        {item.title !== "Services" ? (
                                            <Link to={item.path}>
                                                {item.icon}
                                                <span>{item.title}</span>
                                            </Link>
                                        ) : null}
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            })} */}
            </ul>
          </nav>
        </header>
      )}
    </>
  );
};

export default Navbar;
