import React, { useContext, useEffect, useState } from "react";
import HeroSection from "../../components/Home/HeroSection";
import ProductSection from "../../components/Home/ProductSection";
import AllProducts from "../../components/Home/AllProducts";
import Testimonials from "../../components/Home/Testimonials";
import { getArrivalsHome, getBanners, getProducts } from "../../api/API_GET";
import AboutSection from "../../components/Home/AboutSection";
import Banner from "../../components/HomeBanner/Banner";
import styles from "../../components/Home/HeroSection.module.css";
import { ProductContext } from "../../context/ProductContext";

const Home = () => {
  const [banner, setBanner] = useState([]);
  const { products, setProducts } = useContext(ProductContext);
  const [arrivals, setArrivals] = useState([]);
  // const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchApi = async () => {
      await getBanners(setBanner, setLoading);
      await getProducts(setProducts, setLoading);
      await getArrivalsHome(setArrivals, setLoading);
    };
    fetchApi();
  }, []);

  useEffect(() => {
    console.log(arrivals);
    if (arrivals.length === 0) {
      //Create copy of products
      const temp = [...products];
      //Get only 6 products
      temp.splice(6, temp.length - 6);
      setArrivals(temp);
    }
  }, [products, arrivals]);

  useEffect(() => {
    // console.log(banner);
    //Arrange banner in order of type
    // const newArrival = banner.filter(item => item.type === "NEW_ARRIVAL");
    // //set banner
    // setNewArrivalBanner(newArrival[0]);
  }, [banner]);

  return (
    <div>
      <HeroSection
        banner={banner}
        loading={loading}
      />
      <AboutSection />
      <div className={styles.productDiv}>
        {banner?.map((item, index) => {
          if (item.type === "BANNER_ONE") {
            return (
              <Banner
                key={index}
                image={item.image}
                title={item.title}
                price={item.price}
              />
            );
          }
        })}
        {banner?.map((item, index) => {
          if (item.type === "BANNER_TWO") {
            return (
              <Banner
                key={index}
                image={item.image}
                title={item.title}
                price={item.price}
              />
            );
          }
        })}
      </div>
      <ProductSection
        banner={banner}
        products={arrivals}
        loading={loading}
      />
      <AllProducts
        products={shuffle(products)}
        loading={loading}
      />
      <Testimonials />
    </div>
  );
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default Home;
