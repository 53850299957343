import React, { useEffect, useState } from "react";
import styles from "./ProductSection.module.css";
import image from "../../images/product5.webp";
import component1 from "../../images/Component5.png";
import component2 from "../../images/Component8.png";
import Cart from "../CartAltered/Cart";
import Card from "../CardAltered/Card";
import { Loader } from "@mantine/core";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import products from '../../Product.json';
// import products from "../../Products";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const ProductSection = ({ banner, products, loading }) => {
  const [newArrivalBanner, setNewArrivalBanner] = useState([]);
  useEffect(() => {
    // console.log(banner);
    // Arrange banner in order of type
    const newArrival = banner.filter(item => item.type === "NEW_ARRIVAL");
    //set banner
    setNewArrivalBanner(newArrival[0]);
  }, [banner]);

  const [showCart, setShowCart] = useState(false);
  return (
    <>
      <div className='headerAll hiddenHeading'>
        <img
          className={styles.component1}
          src={component1}
          alt='commponnet'
        />
        <h1
          className={styles.headingh1}
          style={{ fontSize: "50px", color: "#03383c" }}
        >
          New Arrivals
        </h1>
        <img
          className={styles.component1}
          src={component2}
          alt='commponnet'
        />
      </div>
      {/* <h1 style={{ textAlign: 'center', fontSize: '50px', margin: '2em 0 0 0 ' }}>New Arrivals</h1> */}
      <div className={styles.productSection}>
        <div
          className={showCart ? `${styles.model} ${styles.open}` : styles.model}
        >
          <div className={styles.SideCart}>
            <Cart setShowCart={setShowCart} />
          </div>
        </div>
        <div className={styles.LeftDiv}>
          {newArrivalBanner && newArrivalBanner?.type === "NEW_ARRIVAL" ? (
            <>
              <img
                src={`data:image/png;base64,${newArrivalBanner?.image?.data}`}
                alt='imageleft'
              />
              <div className={styles.productName}>
                <p>{newArrivalBanner?.title}</p>
              </div>
              <div className={styles.productPrice}>
                <p>$ {newArrivalBanner?.price}</p>
              </div>
            </>
          ) : (
            <>
              <img
                src={image}
                alt='imageleft'
              />
              <div className={styles.productName}>
                <p>Product Name</p>
              </div>
              <div className={styles.productPrice}>
                <p>Rs. xxxx</p>
              </div>
            </>
          )}
        </div>
        <div className={styles.RightDiv}>
          {!loading ? (
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className={styles.swiper}
            >
              {products.map((item, index) => {
                return (
                  <div key={index}>
                    <SwiperSlide
                      className={styles.swiperSlide}
                      key={index}
                    >
                      <Card
                        item={item}
                        setShowCart={setShowCart}
                        key={index}
                      />
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
          ) : (
            <div className={styles.loading}>
              <Loader color='#7e8865' />
              Loading Products...
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSection;
