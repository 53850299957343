import React, { useContext, useState } from "react";
import { AiOutlineShoppingCart, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styles from "./CardList.module.css";
import { ShopContext } from "../../context/ShopContext";
import Slider from "../Slider/Slider";
import Modal from "../Modal/Modal";
import { createPortal } from "react-dom";
import Button from "../Button/Button";
import { FaStar, FaStarHalf } from "react-icons/fa";

const CardList = props => {
  const { item, setShowCart } = props;
  const navigate = useNavigate();
  const { addToCart, cartItems, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);

  // Generate a random rating between 4 and 5 to determine the number of half stars
  const randomHalfStars = Math.floor(Math.random() * 2); // 0 or 1
  // Generate an array of stars and half stars
  const starArray = Array.from({ length: 4 }, (_, index) => (
    <FaStar
      key={index}
      style={{ fontSize: "18px" }}
    />
  ));

  // Add half stars
  for (let i = 0; i < randomHalfStars; i++) {
    starArray.push(
      <FaStarHalf
        key={`half-${i}`}
        style={{ fontSize: "18px" }}
      />
    );
  }

  // If needed, add remaining full stars to reach a total of 5
  while (starArray.length < 5) {
    starArray.push(
      <FaStar
        key={`empty-${starArray.length}`}
        style={{ fontSize: "18px" }}
      />
    );
  }

  const handleAddToCart = id => {
    // console.log(JSON.stringify(id))
    setShowCart(true);
    addToCart(id);
  };
  const handleButtonClick = value => {
    setModalOpen(false);
  };
  const slides = [
    {
      url: "http://localhost:3000/static/media/product4.c421b0c58a52bd3dd311.jpg",
      title: "Product1",
    },
    {
      url: "http://localhost:3000/static/media/product5.064fac56fff16c1c6959.jpg",
      title: "Product2",
    },
    {
      url: "http://localhost:3000/static/media/product6.4d937b723d9f4049579b.png",
      title: "Product3",
    },
  ];
  const handleImageOnClick = id => {
    // console.log(id)
    navigate(`/product/${id}`);
  };

  const addToCartModal = () => {
    addToCart(item.id);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const containerSlides = {
    width: "520px",
    height: "450px",
    margin: "0",
  };
  // const handleAddToCartPage = (id) => {
  //     navigate('/cart')
  // }
  return (
    <div>
      <div className={styles.card}>
        {modalOpen &&
          createPortal(
            <Modal
              onClose={handleButtonClick}
              onSubmit={handleButtonClick}
              onCancel={handleButtonClick}
            >
              <div className={styles.modalDiv}>
                <div className={styles.ModalImageDiv}>
                  <div style={containerSlides}>
                    <Slider
                      slides={slides}
                      id={item?._id}
                    />
                  </div>
                </div>
                <div className={styles.ModalTextDiv}>
                  <h3>{item?.product_name}</h3>
                  <p>
                    <span>Price:</span> ${item?.selling_price}
                  </p>
                  <p>
                    <span>Avalability:</span>{" "}
                  </p>
                  <p>
                    <span>Quantity:</span>{" "}
                  </p>
                  <p>
                    <span>Size: {item?.size}</span>{" "}
                  </p>
                  <div className={styles.countHandler}>
                    <button onClick={() => removeFromCart(item)}> - </button>
                    <input
                      value={cartItems[item.id]}
                      onChange={e =>
                        updateCartItemCount(Number(e.target.value), item.id)
                      }
                    />
                    <button onClick={() => addToCartModal(item.id)}> + </button>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item.id)}>Add to Cart</button> */}
                  <Button
                    name='Add to Cart'
                    widthProp='50%'
                    action='/cart'
                  ></Button>
                  {/* {<p>Input The number of items</p>} */}
                  {/* action={cartItems[item.id] > 0 ? '/cart' : ''} */}
                  {/* <Button name="Add to wishlist" widthProp="50%"></Button> */}
                  <button
                    className={styles.ProductButton}
                    onClick={() => handleImageOnClick(item?._id)}
                  >
                    View Product &rarr;
                  </button>
                </div>
              </div>
            </Modal>,
            document.body
          )}
        <div className={styles.cardImage}>
          <img
            src={`data:image/png;base64,${item?.images[0].data}`}
            alt={item?.product_name}
          />
          <div
            className={styles.overlay}
            onClick={() => handleImageOnClick(item?._id)}
          ></div>
          <div className={styles.text}>
            <div
              className={styles.Icons}
              onClick={() => handleAddToCart(item)}
            >
              <AiOutlineShoppingCart />
            </div>
            <div className={styles.v1}></div>
            {/* <div className={styles.Icons}>
                            <AiIcons.AiOutlineStar />
                        </div>
                        <div className={styles.v1}></div> */}
            <div className={styles.Icons}>
              <AiOutlineSearch onClick={() => setModalOpen(true)} />
            </div>
          </div>
        </div>
        <div className={styles.cardBody}>
          <h2 className={styles.itemHeading}>
            {item?.product_name}
            <div
              className={styles.headingIcon}
              onClick={() => handleAddToCart(item)}
            >
              <AiOutlineShoppingCart />
            </div>
          </h2>
          <p>
            {starArray.map((star, index) => (
              <React.Fragment key={index}>{star}</React.Fragment>
            ))}
            {/* <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar /> */}
          </p>
          <p style={{ marginBottom: "5px" }}>${item?.selling_price}</p>
          {item.crossed_price === "0" || !item.crossed_price ? null : (
            <p className={styles.CrossPrice}>${item.crossed_price}</p>
          )}
          {item?.description && <p>{item?.description}</p>}
        </div>
      </div>
    </div>
  );
};

export default CardList;
