import React from 'react'
import styles from "./Product.module.css";

export default function ExpandedComponent({data}) {
	return (
		<>
        <div className={styles.Expandable}>
          <p>Product Name : {data.name}</p>
          <p>Price : {data.price}</p>
          <p>Inventory : {data.inventory}</p>
					<p>Sizes: {data.sizes}</p>
					<p>Colors: {data.colors}</p>
					<p>Images: {data.images}</p>
          {/* <p>Status : {data.status}</p> */}
          <p>Created : {data.created}</p>
        </div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </>
	)
}
