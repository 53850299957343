import React from "react";
import styles from "./About.module.css";
import imageBack from "../../images/HomeAbout3.png";
import imageFront from "../../images/HomeAbout2.png";
const AboutSection = () => {
  return (
    <div className={styles.AboutSection}>
      <div className={styles.LeftSection}>
        <h1>Who we are?</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti iure
          unde nostrum eveniet beatae ipsum repudiandae! Nobis hic assumenda,
          enim iusto, laborum reprehenderit amet velit quis voluptates nam
          possimus unde impedit iste maxime facere suscipit voluptatum
          praesentium non? Ipsum voluptatibus repellendus commodi reprehenderit
          quisquam nostrum architecto qui quae iste vel!impedit iste maxime
          facere suscipit voluptatum praesentium non? Ipsum voluptatibus
          repellendus commodi reprehenderit quisquam nostrum architecto qui quae
          iste vel!
        </p>
      </div>
      <div className={styles.RightSection}>
        <div className={styles.BackImage}>
          <img
            className={styles.back}
            src={imageBack}
            alt='imageBack'
          />
          <div className={styles.FrontImage}>
            <img
              src={imageFront}
              alt='imageFront'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
