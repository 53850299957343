import React, { useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_AUTH } from "../../configs/API_CONSTANTS";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  // Handle login form submission
  const handleLoginSubmit = e => {
    e.preventDefault();

    // Login user
    axios
      .post(
        `${API_AUTH}/login`,
        {
          username: username,
          password: password,
        },
        {
          withCredentials: true,
        }
      )
      .then(res => {
        console.log(res);
        console.log(res.data);
        // If remember me is checked, set the cookie
        document.cookie = `token=${res.data.token}; expires=${new Date(
        	new Date().getTime() + 1000 * 60 * 60 * 24 * 30
        ).toUTCString()}`;
        // Redirect to dashboard
        navigate("/dashboard");
      })
      .catch(err => {
        console.log(err);
        alert("Login Failed");
      });

    // // Redirect to dashboard
    // window.location.href = "/dashboard";
  };

  return (
    <div className={styles.LoginPage}>
      <div className={styles.TopHeader}>
        <h1>Groovy</h1>
        <hr />
      </div>
      <div className={styles.LoginForm}>
        <div className={styles.container}>
          <form className={styles.Form}>
            <h3>Admin Login</h3>
            <input
              type='text'
              placeholder='User Name'
              onChange={handleUsernameChange}
            />
            <input
              type='password'
              placeholder='Password'
              onChange={handlePasswordChange}
            />
            <div className={styles.Checkbox}>
              <div className={styles.remeberme}>
                <input
                  type='checkbox'
                  onChange={e => {
                    rememberMe ? setRememberMe(false) : setRememberMe(true);
                  }}
                />
                <strong>Remember me</strong>
              </div>
              <p>
                <a href='#!'>Forgot Your Password?</a>
              </p>
            </div>
            <button onClick={handleLoginSubmit}>Log In</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
