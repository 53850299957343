import axios from "axios";
import {
  API_CATEGORY,
  API_AUTH,
  API_PRODUCT,
  API_ORDER,
  API_NEW_ARRIVAL,
  API_BANNER,
} from "../configs/API_CONSTANTS";

export const postCategory = async (
  category,
  newCategory,
  setCategory,
  setLoading,
  setNotification
) => {
  setLoading(true);
  await axios
    .post(
      API_CATEGORY,
      {
        title: newCategory,
      },
      {
        headers: {
          Authorization: `Bearer ${document.cookie.split("=")[1]}`,
        },
        withCredentials: true,
      }
    )
    .then(res => {
      setCategory([...category, res.data.data]);
    })
    .catch(err => {
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red", // Change the color based on your preference
      });
    });
};

export const postProduct = async (
  product_name,
  product_description,
  selling_price,
  crossed_price,
  cost_per_item,
  quantity,
  product_sku,
  colors,
  product_images,
  sizes,
  selectedOption
) => {
  const formData = new FormData();

  // eslint-disable-next-line array-callback-return
  product_images.map((image, index) => {
    formData.append("images", image.file);
  });
  if (colors.length > 0) {
    // eslint-disable-next-line array-callback-return
    colors.map((color, index) => {
      formData.append("color", color);
    });
  }
  if (sizes.length > 0) {
    // eslint-disable-next-line array-callback-return
    sizes.map((size, index) => {
      formData.append("size", size);
    });
  }
  formData.append("product_name", product_name);
  formData.append("description", product_description);
  formData.append("selling_price", selling_price ? selling_price : 0);
  formData.append("crossed_price", crossed_price ? crossed_price : 0);
  formData.append("cost_per_item", cost_per_item ? cost_per_item : 0);
  formData.append("quantity", quantity ? quantity : 0);
  formData.append("sku", product_sku);
  formData.append("category", selectedOption);
  try {
    const res = await axios.post(API_PRODUCT, formData, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
      withCredentials: true,
    });

    return {
      status: res.status,
      message: res.data.message,
    };
  } catch (err) {
    console.error(err);

    return {
      status: err.response.status,
      message: err.response.data.error,
    };
  }
};

export const postNewArrival = async (
  product_id,
  product_name,
  setNotification
) => {
  console.log(document.cookie.split("=")[1]);
  await axios
    .post(
      API_NEW_ARRIVAL,
      {
        title: product_name,
        id: product_id,
      },
      {
        headers: {
          Authorization: `Bearer ${document.cookie.split("=")[1]}`,
        },
        withCredentials: true,
      }
    )
    .then(res => {
      setNotification({
        open: true,
        message: res.data.message,
        color: "green",
      });
    })
    .catch(err => {
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red",
      });
    });
};

export const postBanner = async (
  banner_title,
  banner_price,
  banner_type,
  banner_image
) => {
  const formData = new FormData();
  formData.append("title", banner_title);
  formData.append("price", banner_price);
  formData.append("type", banner_type);
  formData.append("image", banner_image);
  try {
    const res = await axios.post(API_BANNER, formData, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
      withCredentials: true,
    });

    return {
      status: res.status,
      message: res.data.message,
    };
  } catch (err) {
    console.error(err);

    return {
      status: err.response.status,
      message: err.response.data.error,
    };
  }
};

export const postOrder = async (
  data,
  setNotification,
  setLoading,
  setResponse
) => {
  setLoading(true);
  await axios
    .post(API_ORDER, data, {
      withCredentials: true,
    })
    .then(res => {
      setNotification({
        open: true,
        message: res.data.message,
        color: "green",
      });
      setResponse(res);
      console.log(res.data);
    })
    .catch(err => {
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red",
      });
      setResponse(err.response);
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });
};
