import axios from "axios";
import {
  API_CATEGORY,
  API_AUTH,
  API_PRODUCT,
  API_ORDER,
  API_NEW_ARRIVAL,
  API_BANNER,
} from "../configs/API_CONSTANTS";

export const deleteCategory = async (
  id,
  category,
  setCategory,
  setNotification
) => {
  await axios
    .delete(`${API_CATEGORY}/${id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setCategory(category.filter(item => item._id !== id));
      setNotification({
        open: true,
        message: res.data.message,
        color: "green", // Change the color based on your preference
      });
      console.log(res.data);
      return res;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const deleteProduct = async (
  id,
  products,
  setProducts,
  setDeleteLoading,
  setNotification
) => {
  setDeleteLoading(true);
  await axios
    .delete(`${API_PRODUCT}/${id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setProducts(products.filter(item => item._id !== id));
      setNotification({
        open: true,
        message: res.data.message,
        color: "green", // Change the color based on your preference
      });
      return res;
    })
    .catch(err => {
      console.log(err);
      return err;
    })
    .finally(() => {
      setDeleteLoading(false);
    });
};

export const deleteNewArrival = async (
  id,
  setNotification,
  newArrivals,
  setNewArrivals
) => {
  await axios
    .delete(`${API_NEW_ARRIVAL}/${id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setNewArrivals(newArrivals.filter(item => item._id !== id));
      setNotification({
        open: true,
        message: res.data.message,
        color: "green", // Change the color based on your preference
      });
      return res;
    })
    .catch(err => {
      console.log(err);
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red", // Change the color based on your preference
      });
      return err;
    });
};

export const deleteBanner = async (
  id,
  setNotification,
  banners,
  setBanners
) => {
  await axios
    .delete(`${API_BANNER}/${id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setBanners(banners.filter(item => item._id !== id));
      setNotification({
        open: true,
        message: res.data.message,
        color: "green",
      });
      return res;
    })
    .catch(err => {
      console.log(err);
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red",
      });
      return err;
    });
};

export const deleteOrder = async (
  id,
  setNotification,
  setLoading,
  orders,
  setOrders
) => {
  setLoading(true);
  await axios
    .delete(`${API_ORDER}/${id}`, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setOrders(orders.filter(item => item._id !== id));
      setNotification({
        open: true,
        message: res.data.message,
        color: "green",
      });
      setLoading(false);
      return res;
    })
    .catch(err => {
      console.log(err);
      setNotification({
        open: true,
        message: err.response.data.error,
        color: "red",
      });
      setLoading(false);
      return err;
    });
};
