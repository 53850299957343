import React from "react";
import svg from "../assets/error.svg";

export default function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={svg}
        style={{
          height: "50vh",
        }}
        alt='page not found'
      />
      {/* <div>Hello There !</div> */}
      <h2>Page Not Found!</h2>
    </div>
  );
}
