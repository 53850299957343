import { createContext, useState } from "react";
import PRODUCTS from "../Products";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  let cart = [];
  const storedCart = localStorage.getItem("cart");
  if (storedCart) {
    cart = JSON.parse(storedCart);
  }
  return cart;
};

const storeInLocalStorage = cart => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

const deleteUnusedImages = cart => {
  cart.forEach(item => {
    const { images } = item.item;
    if (images.length > 1) {
      const updatedImages = images.slice(0, 1);
      item.item.images = updatedImages;
    }
  });
};

export const ShopContextProvider = props => {
  const [cartItems, setCartItems] = useState(getDefaultCart());

  // Remove all images except the first one from each product
  // This is to reduce the size of the cart in local storage

  const getTotalCartAmount = () => {
    let total = 0;
    cartItems?.forEach(item => {
      total += parseInt(item?.item?.selling_price) * parseInt(item?.count);
    });
    return total;
  };
  const addToCart = (item, c) => {
    if (!item) return;

		if(cartItems.length > 4){
			alert('You can add only 5 items in cart')
			return;
		}

    if (cartItems.length === 0 && c === 1) {
			setCartItems([{ count: 1, item }]);
      deleteUnusedImages(cartItems);
      storeInLocalStorage(cartItems);
      return;
    }

    //If color and size are same then increase count
    const existingCartItem = cartItems?.find(
      cartItem =>
        cartItem.item._id === item._id &&
        cartItem.item.color === item.color &&
        cartItem.item.size === item.size
    );

    if (c !== 1) {
      if (existingCartItem) {
        const updatedCart = cartItems.map(cartItem =>
          cartItem.item._id === item._id &&
          cartItem.item.color === item.color &&
          cartItem.item.size === item.size
            ? { ...cartItem, count: parseInt(cartItem.count) + parseInt(c) }
            : cartItem
        );
        setCartItems(updatedCart);
				deleteUnusedImages(updatedCart);
        storeInLocalStorage(updatedCart);
      } else {
        setCartItems(prev => [...prev, { count: c, item }]);
        const updatedCart = [...cartItems, { count: c, item }];
				deleteUnusedImages(updatedCart);
        storeInLocalStorage(updatedCart);
      }
      return;
    }

    if (existingCartItem) {
      const updatedCart = cartItems.map(cartItem =>
        cartItem.item._id === item._id &&
        cartItem.item.color === item.color &&
        cartItem.item.size === item.size
          ? { ...cartItem, count: parseInt(cartItem.count) + 1 }
          : cartItem
      );
      setCartItems(updatedCart);
			deleteUnusedImages(updatedCart);
      storeInLocalStorage(updatedCart);
    } else {
      setCartItems(prev => [...prev, { count: 1, item }]);
      const updatedCart = [...cartItems, { count: 1, item }];
			deleteUnusedImages(updatedCart);
      storeInLocalStorage(updatedCart);
    }
    console.log(cartItems);
  };
  const CancelProductFromCart = item => {
    //Remove item from cart
    const updatedCart = cartItems.filter(
      cartItem =>
        cartItem.item._id !== item._id ||
        cartItem.item.color !== item.color ||
        cartItem.item.size !== item.size
    );
    setCartItems(updatedCart);
		deleteUnusedImages(updatedCart);
    storeInLocalStorage(updatedCart);
  };
  const removeFromCart = i => {
    //Decrease count of item
    const existingCartItem = cartItems.find(
      cartItem =>
        cartItem.item._id === i._id &&
        cartItem.item.color === i.color &&
        cartItem.item.size === i.size
    );
    if (existingCartItem) {
      //Remove item from cart if count is 0
      if (existingCartItem.count === 1) {
        const updatedCart = cartItems.filter(
          cartItem =>
            cartItem.item._id !== i._id ||
            cartItem.item.color !== i.color ||
            cartItem.item.size !== i.size
        );
        setCartItems(updatedCart);
        return;
      }
      const updatedCart = cartItems.map(cartItem =>
        cartItem.item._id === i._id &&
        cartItem.item.color === i.color &&
        cartItem.item.size === i.size
          ? { ...cartItem, count: cartItem.count - 1 }
          : cartItem
      );
      setCartItems(updatedCart);
    }
		deleteUnusedImages(cartItems);
    storeInLocalStorage(cartItems);
  };

  const updateCartItemCount = (item, itemCount) => {
    //Update cart item count
    const existingCartItem = cartItems.find(
      cartItem =>
        cartItem.item._id === item.item._id &&
        cartItem.item.color === item.item.color &&
        cartItem.item.size === item.item.size
    );
    if (existingCartItem) {
      const updatedCart = cartItems.map(cartItem =>
        cartItem.item._id === item.item._id &&
        cartItem.item.color === item.item.color &&
        cartItem.item.size === item.item.size
          ? { ...cartItem, count: itemCount }
          : cartItem
      );
      setCartItems(updatedCart);
			deleteUnusedImages(updatedCart);
      storeInLocalStorage(updatedCart);
    }
  };

  const removeAllItemsFromCart = () => {
    setCartItems([]);
    localStorage.removeItem("cart");
  };

  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    CancelProductFromCart,
    removeAllItemsFromCart,
  };
  // console.log(cartItems)

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};
