import React, { useEffect, useState } from "react";
import styles from "../Products/AddProduct.module.css";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Loader, Notification } from "@mantine/core";
import "react-dropzone-uploader/dist/styles.css";
import { postBanner } from "../../../api/API_POST";

const AddBanner = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [bannerImage, setBannerImage] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [bannerPrice, setBannerPrice] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const bannerAddHandler = async e => {
    e.preventDefault();
    setAddLoading(true);

    if (!bannerName || !bannerPrice || !bannerType || !bannerImage) {
      setNotification({
        open: true,
        message: "Please fill all the fields",
        color: "red",
      });
      setAddLoading(false);
      return;
    }

    const response = await postBanner(
      bannerName,
      bannerPrice,
      bannerType,
      bannerImage,
      setNotification,
      setAddLoading,
      setResponse
    );

    console.log(response);
    if (response.status === 200) {
      setNotification({
        open: true,
        message: response.message,
        color: "green",
      });
      setBannerName("");
      setBannerPrice("");
      setBannerType("");
      setBannerImage("");
    } else {
      setNotification({
        open: true,
        message: response.message,
        color: "red",
      });
    }
    setAddLoading(false);
  };

  const handleSellingPriceInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setBannerPrice(numericValue);
  };

	const handleBannerNameInput = e => {
		// Ensure that the input only contains 30 characters
		const value = e.target.value;
		if (value.length <= 30) {
			setBannerName(value);
		}
	}

  return !loading ? (
    <div className={styles.AddProduct}>
      <div className={styles.Topbar}>
        <div className={styles.Heading}>
          <IconContext.Provider value={{ color: "grey", size: "24px" }}>
            <Link to='/dashboard/banner'>
              <AiOutlineArrowLeft />
            </Link>
          </IconContext.Provider>
          <h6>Add Banner</h6>
        </div>
        <div className={styles.Button}>
          <button onClick={bannerAddHandler}>Add</button>
        </div>
      </div>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => {
              setNotification({ ...notification, open: false });
            }}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      <div className={styles.formPart}>
        <form>
          <div className={styles.gridContainer}>
            {/* First Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Banner Name<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                placeholder='eg. Bags'
                required
                value={bannerName}
                onChange={handleBannerNameInput}
              ></input>
              <label>
                <h6>
                  Price to Display<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                min='0'
                placeholder='eg. 100'
                value={bannerPrice}
                required
                onChange={handleSellingPriceInput}
              ></input>
              <label>
                <h6>
                  Select Banner Type<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <select
                required
                value={bannerType}
                onChange={e => setBannerType(e.target.value)}
              >
                <option value=''>Select Banner Type</option>
                <option value='BANNER_ONE'>Banner One</option>
                <option value='BANNER_TWO'>Banner Two</option>
                <option value='NEW_ARRIVAL'>New Arrival</option>
              </select>
              <div className={styles.Button}>
                <button
                  disabled={addLoading}
                  onClick={bannerAddHandler}
                >
                  {addLoading && (
                    <Loader
                      style={{ marginRight: "10px" }}
                      size='sm'
                      color='#7e8865'
                    />
                  )}
                  Add Banner
                </button>
              </div>
            </div>
            {/* SECOND ITEM SELECT IMAGE */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Banner Image<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='file'
                accept='image/*'
                single
                required
                onChange={e => setBannerImage(e.target.files[0])}
              />
              <label>
                <h6>
                  Image Preview<span style={{ color: "red" }}>*</span>
                </h6>
                {bannerImage ? (
                  <img
                    src={URL.createObjectURL(bannerImage)}
                    alt=''
                    style={{
                      width: "500px",
                      height: "400px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <div className={styles.noImage}>
                    <h6>No Image Selected</h6>
                  </div>
                )}
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div className={styles.loader}>
      <Loader color='#7e8865' />
    </div>
  );
};

export default AddBanner;
