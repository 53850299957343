import axios from "axios";
import {
  API_CATEGORY,
  API_AUTH,
  API_PRODUCT,
  API_ORDER,
  API_NEW_ARRIVAL,
  API_BANNER,
} from "../configs/API_CONSTANTS";

export const getCategory = async (setCategory, setLoading) => {
  setLoading(true);
  await axios
    .get(API_CATEGORY, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setCategory(res.data.data);
      return res;
    })
    .catch(err => {
      console.log(err);
      return err;
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getProducts = async (setProduct, setLoading) => {
  setLoading(true);
  await axios
    .get(API_PRODUCT)
    .then(res => {
      setProduct(res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getProductFromId = async (id, setProduct, setLoading) => {
  setLoading(true);
  await axios
    .get(`${API_PRODUCT}/${id}`)
    .then(res => {
      setProduct(res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getNewArrivals = async (setNewArrivals, setLoading) => {
  setLoading(true);
  await axios
    .get(API_NEW_ARRIVAL)
    .then(res => {
      setNewArrivals(res.data.data);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getArrivalsHome = async (setNewArrivals, setLoading) => {
  setLoading(true);
  await axios
    .get(API_NEW_ARRIVAL)
    .then(res => {
      setNewArrivals(res?.data?.arrivals);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getBanners = async (setBanners, setLoading) => {
  setLoading(true);
  await axios
    .get(API_BANNER)
    .then(res => {
      setBanners(res.data.data);
      return res;
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getOrders = async (setOrders, setLoading) => {
  setLoading(true);
  await axios
    .get(API_ORDER, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setOrders(res.data.data);
      return res;
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getCategoryProducts = async (
  id,
  setRecords,
  setProductLoading,
  setNotification
) => {
  setProductLoading(true);
  await axios
    .get(`${API_CATEGORY}/products/${id}`)
    .then(res => {
      setRecords(res.data.data);
      return res;
    })
    .catch(err => {
      setNotification({
        open: true,
        message: err.response.data.message,
        severity: "error",
        color: "red",
      });
      console.log(err);
    })
    .finally(() => {
      setProductLoading(false);
    });
};

export const logoutAdmin = async () => {
  console.log(API_AUTH + "/logout");
  await axios
    .get(`${API_AUTH}/logout`, {
      withCredentials: true,
    })
    .then(res => {
      //Remove cookie
      // document.cookie = `token=;`;
      if (res.status === 200) {
        window.location.href = "/dashboard/Login";
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const searchProduct = async (search, setRecords, setLoading) => {
  setLoading(true);
  await axios
    .get(`${API_PRODUCT}/search/?name=${search}`)
    .then(res => {
      setRecords(res.data.data);
      return res;
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};
