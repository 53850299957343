import { createContext, useState } from "react";

export const ProductContext = createContext(null);

const setDefaultProduct = () => {
  let product = [];
  return product;
};

export const ProductContextProvider = props => {
  const [products, setProducts] = useState(setDefaultProduct());

  const getProducts = async () => {
    return products;
  };

  const addToProduct = item => {
    return setProducts(item);
  };

  const removeFromProduct = item => {
    const itemId = item._id;
   const newProduct = products.filter(item => item._id !== itemId);
    setProducts(newProduct);
  };

  const contextValues = {
    products,
    getProducts,
    setProducts,
    addToProduct,
  };

  return (
    <ProductContext.Provider value={contextValues}>
      {props.children}
    </ProductContext.Provider>
  );
};
