import React, { useState, useEffect, useMemo, useContext } from "react";
import styles from "./Shop.module.css";
import TopBanner from "../../components/TopBanner/TopBanner";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import Card from "../../components/CardAltered/Card";
import Cart from "../../components/CartAltered/Cart";
// import products from '../../Product.json'
// import PRODUCTS from "../../Products";
import CardList from "../../components/CardList/CardList";
import { Loader } from "@mantine/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { getCategory, getProducts } from "../../api/API_GET";
import { ProductContext } from "../../context/ProductContext";
import { AiOutlineRight } from "react-icons/ai";

const Shop = () => {
  const [View, setView] = useState("Grid");
  const { products, setProducts } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [category, setCategory] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    const fetchApi = async () => {
      await getProducts(setProducts, setLoading);
      await getCategory(setCategory, setLoading);
    };
    fetchApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setRecords(products);
  }, [products, category, searchCategory]);

  useEffect(() => {
    //Featured products choose 6 random products
    // Copy products array
    const newProductToSplice = [...products];
    const featuredProducts = newProductToSplice
      .sort(() => Math.random() - Math.random())
      .slice(0, 6);
    setFeatured(featuredProducts);
  }, [products]);

  useEffect(() => {
    const filterAccordingToCategory = () => {
      console.log(searchCategory._id);
      const categoryId = searchCategory._id;

      const newProducts = products.filter(product => {
        return product.category === categoryId;
      });
      console.log("NEW RECORDS", newProducts);
      setRecords(newProducts);
    };
    filterAccordingToCategory();
  }, [searchCategory]);

  // useEffect(() => {
  // }, [records]);

  const handleViewClick = view => {
    setView(view);
  };
  const [SortBy, setSortBy] = useState("Featured");

  const [FromPrice, setFromPrice] = useState("0");
  const [ToPrice, setToPrice] = useState("0");

  const handleFilterPrice = () => {
    // Convert FromPrice and ToPrice to numbers
    const fromPriceNumber = parseFloat(FromPrice);
    const toPriceNumber = parseFloat(ToPrice);
    // Filter products based on price range
    const filteredProducts = products.filter(product => {
      const productPrice = parseFloat(product?.selling_price);
      return productPrice >= fromPriceNumber && productPrice <= toPriceNumber;
    });
    setRecords(filteredProducts);
  };

  const sortedProducts = useMemo(() => {
    return [...records].sort((a, b) => {
      if (SortBy === "Price, low to high") {
        return parseFloat(a?.selling_price) - parseFloat(b?.selling_price);
      } else if (SortBy === "Price, high to low") {
        return parseFloat(b?.selling_price) - parseFloat(a?.selling_price);
      }
      return 0;
    });
  }, [SortBy, records]);
  useEffect(() => {
    setRecords(sortedProducts);
    console.log(category);
    // eslint-disable-next-line
  }, [SortBy]);

  const handleClearPrice = () => {
    setRecords(products);
    setFromPrice("0");
    setToPrice("0");
  };

  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 6; // Number of items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const numberofPages = Math.ceil(records.length / itemsPerPage);

  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className={styles.Shop}>
        <div
          className={showCart ? `${styles.model} ${styles.open}` : styles.model}
        >
          <div className={styles.SideCart}>
            <Cart setShowCart={setShowCart} />
          </div>
        </div>
        <TopBanner
          Pagename='Shop'
          PageLink='/Shop'
        />
        {loading ? (
          <div className={styles.loader}>
            <Loader color='#7e8865' /> Loading products...
          </div>
        ) : (
          <div className={styles.OuterShop}>
            <div className={styles.LeftSection}>
              <div className={styles.LeftCategorySection}>
                <h3>Category</h3>
                <hr />
                <div className={styles.listDiv}>
                  <ul>
                    {category?.map(item => {
                      return (
                        <div
                          className={styles.ListElement}
                          onClick={() => setSearchCategory(item)}
                        >
                          <h4>{item.title} </h4>
                          <AiOutlineRight style={{ justifyContent: "end" }} />
                        </div>
                      );
                    })}
                  </ul>
                </div>
                <h3>Recommended to you</h3>
                <hr />
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  pagination={{ clickable: true }}
                  className={styles.swiper}
                >
                  {/* {products.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <SwiperSlide className={styles.swiperSlide} key={index}>
                                                <Card item={item} setShowCart={setShowCart} key={index} showCart={showCart} />
                                            </SwiperSlide>
                                        </div>
                                    )
                                })} */}
                  {/* Only Map 6 items */}
                  {featured.map((item, index) => {
                    return (
                      <div key={index}>
                        <SwiperSlide
                          className={styles.swiperSlide}
                          key={index}
                        >
                          <Card
                            data={item}
                            item={item}
                            setShowCart={setShowCart}
                            key={index}
                            showCart={showCart}
                          />
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>

                <h3>Price</h3>
                <hr />
                <div className={styles.PriceDiv}>
                  <p>The highest price is $400</p>
                  <div className={styles.Price}>
                    <div className={styles.PriceInput}>
                      <label>From $</label>
                      <input
                        type='number'
                        min='0'
                        value={FromPrice}
                        onChange={e => setFromPrice(e.target.value)}
                      />
                    </div>
                    <div className={styles.PriceInput}>
                      <label>To $</label>
                      <input
                        type='number'
                        min='0'
                        value={ToPrice}
                        onChange={e => setToPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <button onClick={handleFilterPrice}>Find</button>
                  <button onClick={handleClearPrice}>Clear All</button>
                </div>
              </div>
            </div>
            <div className={styles.RightSection}>
              <div className={styles.SortDiv}>
                <div className={styles.Views}>
                  <div
                    className={
                      View === "Grid"
                        ? `${styles.GridView} ${styles.active}`
                        : styles.GridView
                    }
                    onClick={() => handleViewClick("Grid")}
                  >
                    <BsFillGrid3X3GapFill />
                  </div>
                  <div
                    className={
                      View === "List"
                        ? `${styles.ListView} ${styles.active}`
                        : styles.ListView
                    }
                    onClick={() => handleViewClick("List")}
                  >
                    <FaThList />
                  </div>
                </div>
                <div className={styles.Category}>
                  <div>
                    Sort By :
                    <select
                      name='filter'
                      id='cars'
                      value={SortBy}
                      onChange={e => setSortBy(e.target.value)}
                    >
                      <option value='Featured'>Featured</option>
                      <option value='Price, low to high'>
                        Price, low to high
                      </option>
                      <option value='Price, high to low'>
                        Price, high to low
                      </option>
                      <option value='BestSelling'>BestSelling</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* records.map((item, index) => { */}
              <div className={styles.DisplayProduct}>
                {records.length === 0 && (
                  <h2 style={{ justifyContent: "center" }}>
                    No Products Found
                  </h2>
                )}
                {View === "Grid" &&
                  currentItems.map((item, index) => {
                    return (
                      <div
                        className={styles.EachProduct}
                        key={index}
                      >
                        <Card
                          item={item}
                          key={index}
                          description='false'
                          setShowCart={setShowCart}
                        />
                      </div>
                    );
                  })}
                {View === "List" &&
                  currentItems.map((item, index) => {
                    return (
                      <div
                        className={styles.ListProduct}
                        key={index}
                      >
                        <CardList
                          setShowCart={setShowCart}
                          item={item}
                          key={index}
                        />
                        <hr />
                      </div>
                    );
                  })}
              </div>
              <div className={styles.Pagination}>
                <button
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0, { behavior: "smooth" });
                  }}
                >
                  <BsArrowLeftCircle />
                </button>

                {Array.from({ length: numberofPages }, (_, index) => (
                  <div
                    className={
                      currentPage === index + 1 ? styles.activePage : ""
                    }
                    key={index}
                    onClick={() => {
                      setCurrentPage(index + 1);
                      window.scrollTo(0, 0, { behavior: "smooth" });
                    }}
                  >
                    {index + 1}
                  </div>
                ))}

                {/* <div>{currentPage}</div> */}
                <button
                  disabled={indexOfLastItem >= records.length}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0, { behavior: "smooth" });
                  }}
                >
                  <BsArrowRightCircle />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Shop;
