import React from "react";
import styles from "../Home/HeroSection.module.css";

export default function Banner({ image, title, price }) {
  return (
      <div className={styles.product}>
        <img
          src={`data:image/png;base64,${image.data}`}
          alt={title}
        />
        <div className={styles.overlay}>
          <div className={styles.text}>{title}</div>
        </div>
        <div className={styles.overlayTwo}>
          <div className={styles.text}>{title}</div>
        </div>
        <div className={styles.productName}>
          <p>{title}</p>
        </div>
        <div className={styles.productPrice}>
          <p>$ {price}</p>
        </div>
      </div>
  );
}
