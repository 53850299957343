import React, { useEffect, useState } from "react";
import styles from "./Arrival.module.css";
import { createPortal } from "react-dom";
import styles1 from "./Products/tableOwn.module.css";
import { Loader, Notification } from "@mantine/core";
import DataTable from "react-data-table-component";
import { getBanners, getNewArrivals } from "../../api/API_GET";
import { RiDeleteBin5Line } from "react-icons/ri";
import Modal from "../../components/Modal/Modal";
import { deleteBanner, deleteNewArrival } from "../../api/API_DELETE";
import { MdError } from "react-icons/md";
import { Link } from "react-router-dom";

export default function NewArrivals() {
  const icon = <MdError />;
  const [banner, setBanner] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    getBanners(setBanner, setLoading);
  }, []);

  useEffect(() => {
    setRecords(
      banner.map((item, index) => ({
        id: item._id,
        name: item.title,
        price: item.price,
				type: item.type,
        image: item.image,
        sn: index + 1,
      }))
    );
  }, [banner, setBanner]);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "2px",
      },
    },
  };

  const handleCloseButton = () => {
    setModalOpen(false);
  };

  const handleButtonClick = (e, id, name) => {
    e.preventDefault();
    setSelectedRow({ id, name });
    setModalOpen(true);
  };

  const handleProductDelete = async e => {
    e.preventDefault();
    setDeleteLoading(true);
    await deleteBanner(selectedRow.id, setNotification, banner, setBanner);
    setModalOpen(false);
    setDeleteLoading(false);
  };

  const columns = [
    {
      name: "#",
      selector: row => row.sn,
      sortable: true,
      width: "5%",
    },
    {
      name: "Banner Title",
      selector: row => row.name,
      sortable: true,
      width: "20%",
    },
    {
      name: "Price",
      selector: row => row.price,
      sortable: true,
      width: "10%",
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
      width: "10%",
    },
    {
      name: "Image",
      cell: row => (
        <>
          <img
            src={`data:image/png;base64,${row.image.data}`}
            alt=''
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        </>
      ),
      minWidth: "20%",
    },
    {
      name: "Action",
      button: true,
      cell: row => (
        <>
          <button
            className='btn btn-outline btn-xs'
            onClick={e => handleButtonClick(e, row.id, row.name)}
            style={{ color: "red", fontSize: "20px" }}
          >
            <RiDeleteBin5Line />
          </button>
        </>
      ),
      minWidth: "15%",
    },
  ];
  return (
    <div className={styles.Category}>
      {modalOpen &&
        createPortal(
          <Modal
            onClose={handleCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Confirm Delete</h1>
              <p>
                Are you sure to delete <strong>{selectedRow.name}</strong>?
              </p>
              <button
                disabled={deleteLoading}
                className={styles1.ModalButton}
                onClick={handleProductDelete}
              >
                {deleteLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Delete
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => setNotification({ ...notification, open: false })}
            icon={icon}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      {!loading ? (
        <>
          <div className={styles.Heading}>
            <h6>Banners</h6>
            <div className={styles.search}>
              <Link to='/dashboard/add_banner'>
                <button>Add Banner</button>
              </Link>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={records}
            pagination
            fixedHeader
            customStyles={customStyles}
          />
        </>
      ) : (
        <div className={styles.loader}>
          <Loader color='#7e8865' />
        </div>
      )}
      {/* <TableOwn modalOpenTable={modalOpenTable} /> */}
    </div>
  );
}
