import React, { useContext, useEffect, useState } from "react";
import styles from "./Product.module.css";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
// eslint-disable-next-line// eslint-disable-next-line
import { createPortal } from "react-dom";
import styles1 from "./tableOwn.module.css";
import Modal from "../../../components/Modal/Modal";
import { Link } from "react-router-dom";
import { Loader, Notification } from "@mantine/core";
// import TableOwn from './tableOwn'
import DataTable from "react-data-table-component";
import { ProductContext } from "../../../context/ProductContext";
import { getProducts } from "../../../api/API_GET";
import ExpandedProductComponent from "./ExpandedProductComponent";
import { deleteProduct } from "../../../api/API_DELETE";
import { MdError, MdRefresh } from "react-icons/md";
import { CiBookmarkPlus } from "react-icons/ci";
import { postNewArrival } from "../../../api/API_POST";

const Products = () => {
  const icon = <MdError />;

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const customStyles = {
    // rows: {
    //     style: {
    //         paddingLeft: '10px',
    //         paddingRight: '10px' // override the row height
    //     },
    // },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "2px",
      },
    },
  };
  const parseCustomDate = dateString => {
    const dateParts = dateString.split(" ");
    const month = dateParts[0];
    const day = parseInt(dateParts[1].replace(",", ""), 10);
    const year = parseInt(dateParts[2], 10);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const time = dateParts[4].split(" ");
    const timeParts = time[0].split(":");
    let hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    if (dateParts[5] === "PM" && hours !== 12) {
      hours += 12;
    }
    return new Date(year, months.indexOf(month), day, hours, minutes);
  };
  const DateSort = (rowA, rowB) => {
    const a = parseCustomDate(rowA.created);
    const b = parseCustomDate(rowB.created);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const PriceSort = (rowA, rowB) => {
    const a = Number(rowA.price);
    const b = Number(rowB.price);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const InventorySort = (rowA, rowB) => {
    const a = Number(rowA.inventory);
    const b = Number(rowB.inventory);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "#",
      selector: row => row.sn,
      sortable: true,
      width: "5%",
    },
    {
      name: "Product Name",
      selector: row => row.name,
      sortable: true,
      width: "20%",
    },
    {
      name: "SKU",
      selector: row => row.sku,
      width: "15%",
      sortable: true,
    },
    {
      name: "Price",
      selector: row => row.price,
      width: "10%",
      sortable: true,
      sortFunction: PriceSort,
    },
    {
      name: "Inventory",
      selector: row => row.inventory,
      sortable: true,
      width: "10%",
      sortFunction: InventorySort,
    },

    {
      name: "Created",
      selector: row => row.created,
      width: "20%",
      sortFunction: DateSort,
    },
    {
      name: "Actions",
      button: true,
      cell: row => (
        <>
          <Link to={`/dashboard/edit_product/${row.id}`}>
            <button
              className='btn btn-outline btn-xs'
              // onClick={(e) => handleButtonClick(e, row.id)}
              style={{ color: "green", fontSize: "20px" }}
            >
              <BiEdit />
            </button>
          </Link>
          <button
            className='btn btn-outline btn-xs'
            onClick={e => handleButtonClick(e, row.id, row.name)}
            style={{ color: "red", fontSize: "20px" }}
          >
            <RiDeleteBin5Line />
          </button>
          <button
            className='btn btn-outline btn-xs'
            onClick={e => handleNewArrivalButtonClick(e, row.id, row.name)}
            style={{ color: "blue", fontSize: "12px" }}
          >
            <CiBookmarkPlus />
            New Arrival
          </button>
        </>
      ),
      minWidth: "20%",
    },
  ];

  const { products, setProducts } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getProducts(setProducts, setLoading); // assuming getProducts returns a promise
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setProducts]);

  useEffect(() => {
    setRecords(
      products.map((product, index) => ({
        id: product._id,
        name: product.product_name,
        price: product.selling_price,
        sku: product.sku,
        inventory: product.quantity,
        sizes: product.size && product.size.map((size, index) => `${size} ,`),
        colors:
          product.color && product.color.map((color, index) => `${color} ,`),
        images: product.images && product.images.length,
        created: new Date(product.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        sn: index + 1,
      })) || []
    );
  }, [setProducts, products]);

  const [records, setRecords] = useState([]);
  const handleFilter = e => {
    const newdata = products.filter(data => {
      return data.product_name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    console.log("NEW DATA", newdata);
    setRecords(
      newdata.map((product, index) => ({
        id: product._id,
        name: product.product_name,
        price: product.selling_price,
        sku: product.sku,
        inventory: product.quantity,
        sizes:
          product.size &&
          product.size.map((size, index) =>
            index === product.size.length - 1 ? "" : `${size} ,`
          ),
        colors:
          product.color &&
          product.color.map((color, index) =>
            index === product.color.length - 1 ? "" : `${color} ,`
          ),
        images: product.images && product.images.length,
        created: new Date(product.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        sn: index + 1,
      })) || []
    );
  };

  // Modal Open For Delete
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseButton = value => {
    setModalOpen(false);
  };
  const [selectedRow, setselectedRow] = useState();
  const handleButtonClick = (e, id, name) => {
    // alert('You clicked the button')
    e.preventDefault();
    setselectedRow({ id, name });
    setModalOpen(true);
  };

  const [modalOpenNewArrival, setModalOpenNewArrival] = useState(false);
  const handleNewArrivalCloseButton = value => {
    setModalOpenNewArrival(false);
  };
  const handleNewArrivalButtonClick = (e, id, name) => {
    // alert('You clicked the button')
    e.preventDefault();
    setselectedRow({ id, name });
    setModalOpenNewArrival(true);
  };

  const handleAddToNewArrival = async e => {
    e.preventDefault();
    await postNewArrival(
      selectedRow.id,
      selectedRow.name,
      setNotification
    ).finally(() => {
      setModalOpenNewArrival(false);
    });
  };

  const handleProductDelete = async e => {
    e.preventDefault();
    setDeleteLoading(true);
    await deleteProduct(
      selectedRow.id,
      products,
      setProducts,
      setDeleteLoading,
      setNotification
    ).finally(() => {
      setDeleteLoading(false);
      setModalOpen(false);
    });
  };

  const handleRefreshClick = async e => {
    const fetchData = async () => {
      try {
        await getProducts(setProducts, setLoading); // assuming getProducts returns a promise
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  };

  return (
    <div className={styles.Category}>
      {modalOpenNewArrival &&
        createPortal(
          <Modal
            onClose={handleNewArrivalCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Confirm Add</h1>
              <p>
                Are you sure to add
                <strong> {selectedRow.name}</strong> to New Arrival?
              </p>
              <button
                className={styles1.NewArrivalModal}
                onClick={handleAddToNewArrival}
              >
                Add
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {modalOpen &&
        createPortal(
          <Modal
            onClose={handleCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Confirm Delete</h1>
              <p>
                Are you sure to delete <strong>{selectedRow.name}</strong>?
              </p>
              <button
                disabled={deleteLoading}
                className={styles1.ModalButton}
                onClick={handleProductDelete}
              >
                {deleteLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Delete
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => setNotification({ ...notification, open: false })}
            icon={icon}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      {!loading ? (
        <>
          <div className={styles.Heading}>
            <h6>Products</h6>
            <div className={styles.search}>
              <div
                onClick={handleRefreshClick}
                style={{ cursor: "pointer", marginRight: "10px" }}
              >
                <MdRefresh
                  size={25}
                  color='black'
                />
              </div>
              <input
                type='text'
                onChange={handleFilter}
                placeholder='Search By Name ..'
              />
              <Link to='/dashboard/add_product'>
                <button>Add Product</button>
              </Link>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={records}
            pagination
            fixedHeader
            expandableRows
            expandableRowsHideExpander={true}
            expandOnRowClicked={true}
            expandableRowsComponent={ExpandedProductComponent}
            customStyles={customStyles}
          />
        </>
      ) : (
        <div className={styles.loader}>
          <Loader color='#7e8865' />
        </div>
      )}
      {/* <TableOwn modalOpenTable={modalOpenTable} /> */}
    </div>
  );
};

export default Products;
