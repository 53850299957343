import React, { useEffect, useState } from "react";
import TopBanner from "../../components/TopBanner/TopBanner";
import styles from "./Contact.module.css";
import { BsTelephoneFill } from "react-icons/bs";
import { Loader } from "@mantine/core";
import { GrMail } from "react-icons/gr";
import { FaLocationArrow } from "react-icons/fa";
import { sendForm, send } from "@emailjs/browser";

const Contact = () => {
  const [toSend, setToSend] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = email => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(email)) {
      setEmailError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setEmailError(false);
    }
  };
  const handleContactFormSumit = e => {
    e.preventDefault();
    setLoading(true);
    validateEmail(toSend.email);
    console.log(toSend);
    if (emailError) {
      setLoading(false);
      return;
    }
    if (
      toSend.name === "" ||
      toSend.phone === "" ||
      toSend.email === "" ||
      toSend.message === ""
    ) {
      alert("Please fill all the fields");
      setLoading(false);
      return;
    }
    if (toSend.phone.length !== 14) {
      alert("Please enter a valid phone number");
      setLoading(false);
      return;
    }
    send("service_yw86jks", "template_44j55f8", toSend, "bV-QdQuVmmX6e7Rv6")
      .then(res => {
        console.log(res);
        alert("Message Sent Successfully");
        setToSend({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        alert("Message Not Sent");
        setLoading(false);
      });
  };
  return (
    <div className={styles.Contact}>
      <TopBanner
        Pagename='Contact'
        PageLink='/contact'
      />
      <iframe
        title='iframe'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.504354183032!2d85.30445247467095!3d27.670802627103832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19628ba30cb5%3A0x7927837c54013d33!2sWeb%20Tuned%20Studio!5e0!3m2!1sen!2snp!4v1692007897734!5m2!1sen!2snp'
        width='90%'
        height='500'
        style={{ border: "0" }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
      <div className={styles.ContactInfo}>
        <div className={styles.InnerContactInfo}>
          <div className={styles.Icon}>
            <BsTelephoneFill />
          </div>
          <h1>Phone</h1>
          <div>
            <p>0123-456-7890</p>
            <p>0123-456-7890</p>
          </div>
        </div>
        <div className={styles.InnerContactInfo}>
          <div className={styles.Icon}>
            <GrMail />
          </div>
          <h1>Email</h1>
          <div>
            <p>info@example.com</p>
            <p>support@example.com</p>
          </div>
        </div>
        <div className={styles.InnerContactInfo}>
          <div className={styles.Icon}>
            <FaLocationArrow />
          </div>
          <h1>Address</h1>
          <div>
            <p>No: 58 A, East Madison Street,</p>
            <p>Baltimore, MD, USA 4508</p>
          </div>
        </div>
      </div>
      <div className={styles.ContactForm}>
        <div className={styles.container}>
          <h1>Contact Form</h1>
          <form id='contactForm'>
            <div className={styles.gridContainer}>
              <div className={styles.gridItem}>
                <input
                  type='text'
                  placeholder='name'
                  value={toSend.name}
                  onChange={e => setToSend({ ...toSend, name: e.target.value })}
                />
              </div>
              <div className={styles.gridItem}>
                <input
                  type='text'
                  placeholder='phone'
                  value={toSend.phone}
                  onChange={e => {
                    // Ensure that the input only contains numeric characters
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");

                    //Create in (xxx) xxx-xxxx format
                    const formattedValue = `(${numericValue.slice(
                      0,
                      3
                    )}) ${numericValue.slice(3, 6)}-${numericValue.slice(
                      6,
                      10
                    )}`;

                    // Update the state with the formatted value
                    setToSend({ ...toSend, phone: formattedValue });
                  }}
                />
              </div>
              <div className={styles.gridItem}>
                <input
                  type='email'
                  placeholder='email'
                  className={emailError ? styles.error : styles.emailInput}
                  value={toSend.email}
                  onChange={e =>
                    setToSend({ ...toSend, email: e.target.value })
                  }
                />
              </div>
              <div className={`${styles.gridItem} ${styles.Fourthcolumn}`}>
                <textarea
                  placeholder='Message'
                  rows='10'
                  value={toSend.message}
                  onChange={e =>
                    setToSend({ ...toSend, message: e.target.value })
                  }
                />
              </div>
            </div>
            <button
              onClick={handleContactFormSumit}
              disabled={loading}
            >
              {loading && <Loader color='#7e8865' />} Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
