import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cart from "../../components/CartAltered/Cart";
import { Loader } from "@mantine/core";
import styles from "./Product.module.css";
// import products from '../Product.json'
// import PRODUCTS from '../../ProductItems'
// import PRODUCTS from "../../Products";
import TopBanner from "../../components/TopBanner/TopBanner";
import Lightbox from "../../components/LightBox/Lightbox";
import { ShopContext } from "../../context/ShopContext";
import { getProductFromId } from "../../api/API_GET";

// import required modules

const Product = () => {
  const [showCart, setShowCart] = useState(false);
  const [Product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [count, setCount] = useState(1);
  const [items, setItems] = useState({});
  const [options, setoptions] = useState("Description");
  const { id } = useParams();
  const { addToCart, cartItems, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApi = async () => {
      await getProductFromId(id, setProduct, setLoading);
    };
    fetchApi();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    setColor(Product?.color && Product?.color[0]);
    setSize(Product?.size && Product?.size[0]);
  }, [Product, loading]);

  // const handleAddToCart = (id) => {
  //     // console.log(JSON.stringify(id))
  //     setShowCart(true);
  //     const existingCartKeysString = localStorage.getItem('cartKey');
  //     let existingCartKeys = [];

  //     if (existingCartKeysString) {
  //         try {
  //             existingCartKeys = JSON.parse(existingCartKeysString);
  //             if (!Array.isArray(existingCartKeys)) {
  //                 existingCartKeys = [];
  //             }
  //         } catch (error) {
  //             console.error('Error parsing existingCartKeys:', error);
  //             existingCartKeys = [];
  //         }
  //     }
  //     // Add the new key to the array
  //     existingCartKeys.push(id);
  //     // Store the updated array of keys in local storage
  //     localStorage.setItem('cartKey', JSON.stringify(existingCartKeys));
  // }

  const handleAddToCart = (item, c) => {
    // Check color and size are selected
    console.log(c);
    const {
      _id,
      product_name,
      selling_price,
      crossed_price,
      description,
      images,
    } = item;
    if (c === 1) {
      console.log("c is 1");
      const items = {
        _id,
        product_name,
        selling_price,
        crossed_price,
        description,
        images,
        color,
        size,
      };
      addToCart(items, c);
      setShowCart(true);
      return;
    }
    const items = {
      _id,
      product_name,
      selling_price,
      crossed_price,
      description,
      images,
      color,
      size,
    };
    addToCart(items, c);
    setShowCart(true);
  };

  return !loading ? (
    <>
      <div className={styles.ProductOuter}>
        <div
          className={showCart ? `${styles.model} ${styles.open}` : styles.model}
        >
          <div className={styles.SideCart}>
            <Cart setShowCart={setShowCart} />
          </div>
        </div>
        <TopBanner
          Pagename='Product'
          PageLink={`/product/${id}`}
        />
        <div className={styles.SoloProduct}>
          <div className={styles.container}>
            <Lightbox product={Product} />
            {/* <Lightbox img={Product.img} imgArray={Product.imgArray} /> */}
            <div className={styles.DescriptionDiv}>
              <h3>{Product?.product_name}</h3>

              <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                  <span>Price:</span>
                </div>
                <div className={styles.gridItem}>
                  <div className={styles.ProductPrice}>
                    <h1>${Product?.selling_price}</h1>
                    <h5>${Product?.crossed_price}</h5>
                  </div>
                </div>
                <div className={styles.gridItem}>
                  <span>Color:</span>
                </div>
                <div className={styles.gridItem}>
                  <div className={styles.ColorRow}>
                    {Product?.color &&
                      Product?.color?.map(item => (
                        <div
                          key={item}
                          className={styles.colorDiv}
                          style={{
                            backgroundColor: item,
                            cursor: "pointer",
                            border: color === item ? "0px" : "5px solid white",
                            borderRadius: "50%",
                          }}
                          onClick={() => setColor(item)}
                        ></div>
                      ))}
                  </div>
                </div>
                <div className={styles.gridItem}>
                  <span>Size:</span>
                </div>
                <div className={styles.gridItem}>
                  <div className={styles.SizeRow}>
                    {Product?.size &&
                      Product?.size.map(item => (
                        <div
                          key={item}
                          className={styles.sizeDiv}
                          style={{
                            cursor: "pointer",
                            color: size === item ? "#fff" : "",
                            backgroundColor: size === item ? "#7E8865" : "",
                            border: size === item ? "3px solid #7E8865" : "",
                          }}
                          onClick={() => setSize(item)}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className={styles.gridItem}>
                  <span>Quantity:</span>
                </div>
                <div className={styles.gridItem}>
                  <div className={styles.countHandler}>
                    <button
                      onClick={() => {
                        if (count <= 1) {
                          setCount(1);
                          return;
                        }
                        setCount(count - 1);
                      }}
                    >
                      {" "}
                      -{" "}
                    </button>
                    <input
                      min='1'
                      max='100'
                      //Donot allow negative values
                      value={count}
                      onChange={e => {
                        if (e.target.value < 1) {
                          setCount(1);
                          return;
                        }
                        setCount(e.target.value);
                      }}
                    />
                    <button onClick={() => setCount(parseInt(count) + 1)}>
                      {" "}
                      +{" "}
                    </button>
                  </div>
                </div>
                <div className={styles.gridItem}>
                  <span>Description:</span>
                </div>
                <div className={styles.gridItem}>{Product?.description}</div>
              </div>
              <button onClick={() => handleAddToCart(Product, count)}>
                Add to Cart
              </button>
              <button onClick={() => navigate("/shop")}>Go Back To Shop</button>
              <button onClick={() => navigate("/cart")}>Buy Now</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Information}>
        <div className={styles.Buttons}>
          <button
            className={options === "Description" ? styles.clicked : ""}
            onClick={() => setoptions("Description")}
          >
            Description
          </button>
          <button
            className={options === "Shipping" ? styles.clicked : ""}
            onClick={() => setoptions("Shipping")}
          >
            Shipping Information
          </button>
          <button
            className={options === "Reviews" ? styles.clicked : ""}
            onClick={() => setoptions("Reviews")}
          >
            Reviews
          </button>
        </div>
        <div className={styles.Details}>
          {options === "Description" && (
            <div className={styles.Descrption}>
              <strong>Sample Paragraph Text</strong>
              <p>{Product?.description}</p>
            </div>
          )}
          {options === "Shipping" && (
            <div className={styles.Shipping}>
              <strong>Returns Policy</strong>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
                quidem ab maiores! Qui dolores sapiente ducimus ad vero
                assumenda voluptates? Labore, error! Veritatis, accusantium eius
                dolorem magni, molestias, aut id sit porro voluptates totam
                doloremque reprehenderit reiciendis repudiandae distinctio nulla
                blanditiis facilis minima nesciunt quaerat! Nobis, excepturi.
                Nesciunt temporibus perferendis corrupti fuga unde voluptatem,
                dolorem dignissimos natus? Debitis doloribus sequi quisquam a
                amet est, omnis totam repudiandae dolorem eaque illo accusantium
                mollitia magnam error vel! Earum ipsa tenetur excepturi
                consectetur illo, corporis quos sit! Quos, dicta possimus alias
                error amet distinctio, voluptates vel molestiae quis ipsam
                deleniti placeat debitis labore!
              </p>
              <strong>Shipping</strong>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
                natus nobis veniam doloremque velit assumenda vitae magni nihil
                eos accusantium, modi ipsa quibusdam rem earum. Laborum sunt
                reprehenderit animi eum voluptates debitis ratione
                exercitationem nam quos perspiciatis eveniet nulla non eligendi
                molestiae doloremque, quo amet ad, maxime cum, quia suscipit.
                Fugit expedita id illo ex voluptatibus alias, sed aspernatur,
                incidunt, accusantium fugiat autem facere. Iste commodi deleniti
                dicta neque inventore architecto, laborum alias doloremque ipsa,
                harum sed? Quibusdam, hic, voluptates voluptatem velit laborum
                delectus quo ab aliquid nam at illo adipisci. Aspernatur amet
                similique accusantium odio delectus odit, veniam dolore voluptas
                nihil perferendis dolorum eveniet pariatur autem doloribus.
                Temporibus doloribus ratione magnam, nobis expedita quas
                consequatur, eos rerum animi laboriosam sunt nemo neque incidunt
                ipsam, voluptatem est nisi quam? Nemo.
              </p>
            </div>
          )}
          {options === "Reviews" && (
            <div className={styles.Reviews}>
              <strong>Customer Reviews</strong>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className={styles.loader}>
      <Loader color='#7e8865' />
    </div>
  );
};

export default Product;
