import axios from "axios";
import { API_ORDER, API_PRODUCT } from "../configs/API_CONSTANTS";

export const updateProduct = async (id, data, setResponse) => {
  const API_URL = `${API_PRODUCT}/${id}`;
  const formData = new FormData();

  data.deleted_images.map((image, index) => {
    formData.append("deleted_images", image);
  });
  // eslint-disable-next-line array-callback-return
  data.product_images.map((image, index) => {
    formData.append("images", image.file);
  });
  if (data.colors.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.colors.map((color, index) => {
      formData.append("color", color);
    });
  }
  if (data.sizes.length > 0) {
    // eslint-disable-next-line array-callback-return
    data.sizes.map((size, index) => {
      formData.append("size", size);
    });
  }
  formData.append("product_name", data.product_name);
  formData.append("description", data.product_description);
  formData.append("selling_price", data.selling_price ? data.selling_price : 0);
  formData.append("crossed_price", data.crossed_price ? data.crossed_price : 0);
  formData.append("cost_per_item", data.cost_per_item ? data.cost_per_item : 0);
  formData.append("quantity", data.quantity ? data.quantity : 0);
  formData.append("sku", data.product_sku);
  formData.append("category", data.selectedOption);
  formData.append("deleted_images", data.deleted_images);
  console.log("formData:", formData);

  try {
    const res = await axios.put(API_URL, formData, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    });

    return {
      status: res.status,
      message: res.data.message,
    };
  } catch (err) {
    console.error(err);

    return {
      status: err.response.status,
      message: err.response.data.error,
    };
  }
};

export const updateOrder = async (
  id,
  data,
  setNotification,
  setLoading,
  setRecords,
  selectedRow
) => {
  setLoading(true);
  await axios
    .put(`${API_ORDER}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${document.cookie.split("=")[1]}`,
      },
    })
    .then(res => {
      setNotification({
        open: true,
        status: res.status,
        message: res.data.message,
        color: "green",
      });
      setRecords(prev =>
        prev.map(record =>
          record.id === selectedRow.id
            ? { ...record, shipping_status: data.shipping_status }
            : record
        )
      );
      setLoading(false);
    })
    .catch(err => {
      setNotification({
        open: true,
        status: err.response.status,
        message: err.response.data.error,
        color: "red",
      });
      setLoading(false);
    });
};
