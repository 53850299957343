import React, { useContext, useEffect, useState } from "react";
import styles from "./Checkout.module.css";
import TopBanner from "../../components/TopBanner/TopBanner";
import { Loader, Notification } from "@mantine/core";
import PRODUCTS from "../../Products";
import { ShopContext } from "../../context/ShopContext";
import { postOrder } from "../../api/API_POST";

const Checkout = () => {
  const { cartItems, getTotalCartAmount, removeAllItemsFromCart } =
    useContext(ShopContext);
  const totalAmount = getTotalCartAmount();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [cartItems]);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "",
  });
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const validateEmail = e => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(e)) {
      setEmailError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setEmailError(false);
    }
  };

  const handleProceedToPayment = async e => {
    e.preventDefault();
    validateEmail(email);
    if (
      email === "" ||
      phone === "" ||
      firstName === "" ||
      lastName === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      zipCode === ""
    ) {
      setNotification({
        open: true,
        message: "Please fill all the fields.",
        color: "red",
      });
      return;
    }
    if (phone.length !== 12) {
      setNotification({
        open: true,
        message: "Please enter a valid phone number.",
        color: "red",
      });
      return;
    }
    //Cut phone number to 10 digits from -
    const phoneToSubmit =
      phone.slice(0, 3) + phone.slice(4, 7) + phone.slice(8, 12);
    if (cartItems.length === 0) {
      setNotification({
        open: true,
        message: "Your cart is empty. Please add some items to proceed.",
        color: "red",
      });
      return;
    }
    if (!emailError) {
      const cartToProceed = cartItems.map(item => {
        return {
          product_id: item?.item?._id,
          product_name: item?.item?.product_name,
          product_price: item?.item?.selling_price,
          size: item?.item?.size,
          color: item?.item?.color,
          count: item?.count,
        };
      });

      const data = {
        email: email,
        phone: phoneToSubmit,
        first_name: firstName,
        last_name: lastName,
        address: address,
        address_full: apartment,
        product: cartToProceed,
        country: "United States",
        city: city,
        state: state,
        zip_code: zipCode,
        total_price: totalAmount,
        products: cartToProceed,
        payment_method: "Standard",
        shipping_cost: 0,
        payment_status: "Pending",
      };

      // if (apartment !== "") {
      //   data.address_full = apartment;
      // }
      console.log(data);

      await postOrder(data, setNotification, setLoading, setResponse);
      setFirstName("");
      setLastName("");
      setAddress("");
      setApartment("");
      setCity("");
      setState("");
      setZipCode("");
      setEmail("");
      setPhone("");
      setLoading(false);
      removeAllItemsFromCart();
    }
  };

  return (
    <div
      className={styles.Checkout}
      style={{ marginTop: "100px" }}
    >
      <TopBanner
        Pagename='Checkout'
        PageLink='/checkout'
      />
      <div className={styles.InnerCheckout}>
        <div className={styles.FormCheckout}>
          <form>
            <div className={styles.contactDiv}>
              <p>
                <strong>
                  Contact<span style={{ color: "red" }}>*</span>
                </strong>
              </p>
              <input
                type='text'
                name='emailInput'
                className={emailError ? styles.error : styles.emailInput}
                placeholder='email@example.com'
                value={email}
                onChange={e => setEmail(e.target.value)}
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                required
              ></input>
              {emailError && (
                <p className={styles.errorText}>
                  Please enter a valid email address.
                </p>
              )}
            </div>
            <div className={styles.contactDiv}>
              <input
                type='text'
                name='phone'
                className={styles.phoneInput}
                placeholder='999-999-9999'
                value={phone}
                onChange={e => {
                  // Ensure that the input only contains numeric characters
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");

                  //Create in (xxx) xxx-xxxx format
                  const formattedValue = `${numericValue.slice(
                    0,
                    3
                  )}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;

                  // Update the state with the formatted value
                  setPhone(formattedValue);
                }}
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                required
              ></input>
            </div>
            <div className={styles.shippingDiv}>
              <p>
                <strong>
                  Shipping Address<span style={{ color: "red" }}>*</span>
                </strong>
              </p>
              <div className={styles.gridContainer}>
                <div className={`${styles.gridItem} ${styles.span3}`}>
                  <input
                    type='text'
                    placeholder='Country'
                    value='Country - United States'
                  />
                </div>
                <div
                  className={`${styles.gridItem} ${styles.span3} ${styles.gridgrid}`}
                >
                  <div className={`${styles.InnergridItem}`}>
                    <input
                      type='text'
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      placeholder='First Name'
                    />
                  </div>
                  <div className={`${styles.InnergridItem} `}>
                    <input
                      type='text'
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      placeholder='Last Name'
                    />
                  </div>
                </div>
                <div className={`${styles.gridItem} ${styles.span3}`}>
                  <input
                    type='text'
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    placeholder='Address'
                  />
                </div>
                <div className={`${styles.gridItem} ${styles.span3}`}>
                  <input
                    type='text'
                    value={apartment}
                    onChange={e => setApartment(e.target.value)}
                    placeholder='Apartment, suite, etc. (optional)'
                  />
                </div>
                <div className={styles.gridItem}>
                  <input
                    type='text'
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    placeholder='City'
                  />
                </div>
                <div className={styles.gridItem}>
                  {/* <input type='text' placeholder='State' /> */}
                  <select
                    id='stateSelect'
                    name='stateSelect'
                    value={state}
                    onChange={e => setState(e.target.value)}
                  >
                    <option
                      value=''
                      selected
                      disabled
                    >
                      State
                    </option>
                    <option value='Alabama'>Alabama</option>
                    <option value='Alaska'>Alaska</option>
                    <option value='Arizona'>Arizona</option>
                    <option value='Arkansas'>Arkansas</option>
                    <option value='California'>California</option>
                    <option value='Colorado'>Colorado</option>
                    <option value='Connecticut'>Connecticut</option>
                    <option value='Delaware'>Delaware</option>
                    <option value='Florida'>Florida</option>
                    <option value='Georgia'>Georgia</option>
                    <option value='Hawaii'>Hawaii</option>
                    <option value='Idaho'>Idaho</option>
                    <option value='Illinois'>Illinois</option>
                    <option value='Indiana'>Indiana</option>
                    <option value='Iowa'>Iowa</option>
                    <option value='Kansas'>Kansas</option>
                    <option value='Kentucky'>Kentucky</option>
                    <option value='Louisiana'>Louisiana</option>
                    <option value='Maine'>Maine</option>
                    <option value='Maryland'>Maryland</option>
                    <option value='Massachusetts'>Massachusetts</option>
                    <option value='Michigan'>Michigan</option>
                    <option value='Minnesota'>Minnesota</option>
                    <option value='Mississippi'>Mississippi</option>
                    <option value='Missouri'>Missouri</option>
                    <option value='Montana'>Montana</option>
                    <option value='Nebraska'>Nebraska</option>
                    <option value='Nevada'>Nevada</option>
                    <option value='New Hampshire'>New Hampshire</option>
                    <option value='New Jersey'>New Jersey</option>
                    <option value='New Mexico'>New Mexico</option>
                    <option value='New York'>New York</option>
                    <option value='North Carolina'>North Carolina</option>
                    <option value='North Dakota'>North Dakota</option>
                    <option value='Ohio'>Ohio</option>
                    <option value='Oklahoma'>Oklahoma</option>
                    <option value='Oregon'>Oregon</option>
                    <option value='Pennsylvania'>Pennsylvania</option>
                    <option value='Rhode Island'>Rhode Island</option>
                    <option value='South Carolina'>South Carolina</option>
                    <option value='South Dakota'>South Dakota</option>
                    <option value='Tennessee'>Tennessee</option>
                    <option value='Texas'>Texas</option>
                    <option value='Utah'>Utah</option>
                    <option value='Vermont'>Vermont</option>
                    <option value='Virginia'>Virginia</option>
                    <option value='Washington'>Washington</option>
                    <option value='West Virginia'>West Virginia</option>
                    <option value='Wisconsin'>Wisconsin</option>
                    <option value='Wyoming'>Wyoming</option>
                  </select>
                </div>
                <div className={styles.gridItem}>
                  <input
                    type='text'
                    value={zipCode}
                    onChange={e => {
                      // Ensure that the input only contains numeric characters
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );

                      setZipCode(numericValue);
                    }}
                    placeholder='Zip Code'
                  />
                </div>
              </div>
            </div>
            <div className={styles.submitButton}>
              <button
                disabled={loading}
                onClick={handleProceedToPayment}
              >
                Proceed to Payment
                {loading && (
                  <Loader
                    style={{ marginRight: "10px" }}
                    size='sm'
                    color='#7e8865'
                  />
                )}
              </button>
            </div>
          </form>
        </div>
        <div className={styles.VerticalLine}></div>
        <div className={styles.ListCheckout}>
          {cartItems.map((product, index) => {
            return (
              <div key={index}>
                <div
                  className={styles.ItemCard}
                  key={index}
                >
                  <div className={styles.ProductInfo}>
                    <div className={styles.Image}>
                      <img
                        src={`data:image/png;base64,${product?.item?.images[0].data}`}
                        alt='product'
                      />
                    </div>
                    <div className={styles.name}>
                      <h6>
                        <strong>{product?.item?.product_name}</strong>
                      </h6>
                      <h6>Size : {product?.item?.size}</h6>
                    </div>
                  </div>
                  <div className={styles.Price}>
                    <p>${product?.item?.selling_price}</p>
                    <p> x{product?.count}</p>
                  </div>
                </div>
                <hr className={styles.horzontal} />
              </div>
            );
          })}

          {/* 
                    {selected.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className={styles.ItemCard} key={index}>
                                    <div className={styles.ProductInfo}>
                                        <div className={styles.Image}>
                                            <img src={item.img} alt="product" />
                                        </div>
                                        <div className={styles.name}>
                                            <h6><strong>{item.name}</strong></h6>
                                            <h6>Size : {item.size}</h6>
                                        </div>
                                    </div>
                                    <div className={styles.Price}>
                                        <p> {item.price}</p>
                                        <p> x2</p>
                                    </div>
                                </div>
                                <hr className={styles.horzontal} />
                            </div>
                        )
                    })} */}
          <div className={styles.pricing}>
            <div className={styles.pricingItem}>
              <h6>Subtotal</h6>
              <h6>
                <strong>${totalAmount}</strong>
              </h6>
            </div>
            <div className={styles.pricingItem}>
              <h6>Shipping</h6>
              <h6>$</h6>
            </div>
            <div className={styles.pricingItem}>
              <h6>
                <strong>Total</strong>
              </h6>
              <h6>
                USD <strong>${totalAmount}</strong>
              </h6>
            </div>
          </div>
        </div>
      </div>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => {
              setNotification({ ...notification, open: false });
            }}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
    </div>
  );
};

export default Checkout;
