import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styles from "./Order.module.css";
import styles1 from "./Products/tableOwn.module.css";
import Modal from "../../components/Modal/Modal";
import { MdError, MdRefresh } from "react-icons/md";
import { Loader, Notification } from "@mantine/core";
import { RiDeleteBin5Line } from "react-icons/ri";
import { createPortal } from "react-dom";
import { getOrders } from "../../api/API_GET";
import { BiEdit } from "react-icons/bi";
import { updateOrder } from "../../api/API_UPDATE";
import { deleteOrder } from "../../api/API_DELETE";

const Order = () => {
  const icon = <MdError />;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState("");
  const [records, setRecords] = useState([]);
  const [modalShipping, setModalShipping] = useState(false);
  const [shippingStatus, setShippingStatus] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "",
  });
  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 2000);
    }
  }, [notification]);

  useEffect(() => {
    const fetchApi = async () => {
      await getOrders(setOrders, setLoading);
    };
    fetchApi();
  }, []);

  useEffect(() => {
    //Orders loaded
    if (orders.length > 0) {
      setRecords(
        orders?.map((order, index) => {
          return {
            sn: index + 1,
            id: order._id,
            name: order?.first_name + " " + order?.last_name,
            email: order.email,
            address: order.address,
            address_full: order.address_full,
            zip_code: order.zip_code,
            shipping_cost: order.shipping_cost,
            product_name: order.product.product_name,
            price: order.product.product_price,
            quantity_count: order.product.quantity_count,
            size: order.product.size,
            color: order.product.color,
            country: order.country,
            city: order.city,
            phone: order.phone,
            shipping_status: order.shipping_status,
            order_group: order.order_group,
            totalPrice: order.total_price,
            paymentStatus: order.payment_status,
            paymentMethod: order.payment_method,
            status: order.shipping_status,
            created: new Date(order.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            }),
          };
        })
      );
    }
  }, [orders]);

  useEffect(() => {
    // console.log(records);
  }, [records]);
  const customStyles = {
    // rows: {
    //     style: {
    //         paddingLeft: '10px',
    //         paddingRight: '10px' // override the row height
    //     },
    // },
    headCells: {
      style: {
        paddingLeft: "6px", // override the cell padding for head cells
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        paddingLeft: "6px", // override the cell padding for data cells
        paddingRight: "2px",
      },
    },
  };
  const OrderGroupSort = (rowA, rowB) => {
    const a = rowA.order_group;
    const b = rowB.order_group;

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
  };
  const PriceSort = (rowA, rowB) => {
    const a = Number(rowA.totalPrice);
    const b = Number(rowB.totalPrice);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const parseCustomDate = dateString => {
    const dateParts = dateString.split(" ");
    const month = dateParts[0];
    const day = parseInt(dateParts[1].replace(",", ""), 10);
    const year = parseInt(dateParts[2], 10);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const time = dateParts[4].split(" ");
    const timeParts = time[0].split(":");
    let hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    if (dateParts[5] === "PM" && hours !== 12) {
      hours += 12;
    }
    return new Date(year, months.indexOf(month), day, hours, minutes);
  };
  const DateSort = (rowA, rowB) => {
    const a = parseCustomDate(rowA.created);
    const b = parseCustomDate(rowB.created);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const columns = [
    {
      name: "#",
      selector: row => row.sn,
      sortable: true,
      width: "4%",
    },
    {
      name: "Customer Name",
      selector: row => row.name,
      sortable: true,
      width: "12%",
    },
    {
      name: "Product Name",
      selector: row => row.product_name,
      width: "10%",
    },
    {
      name: "Order Group",
      selector: row => row.order_group,
      width: "15%",
      sortable: true,
      sortFunction: OrderGroupSort,
    },
    {
      name: "Total Price",
      selector: row => row.totalPrice,
      sortable: true,
      width: "10%",
      sortFunction: PriceSort,
    },
    {
      name: "Payment Status",
      selector: row => row.paymentStatus,
      width: "10%",
    },
    {
      name: "Payment Method",
      selector: row => row.paymentMethod,
      width: "10%",
    },
    {
      name: "Shipping",
      selector: row => row.shipping_status,
      width: "8%",
      sortable: true,
    },
    {
      name: "Created",
      selector: row => row.created.split(" ").slice(0, 3).join(" "),
      width: "10%",
      sortFunction: DateSort,
    },
    {
      name: "Actions",
      button: true,
      cell: row => (
        <>
          <button
            className='btn btn-outline btn-xs'
            onClick={e =>
              handleUpdateClick(e, row.id, row.name, row.product_name)
            }
            style={{ color: "green", fontSize: "20px" }}
          >
            <BiEdit />
          </button>
          <button
            className='btn btn-outline btn-xs'
            onClick={e =>
              handleButtonClick(e, row.id, row.name, row.product_name)
            }
            style={{ color: "red", fontSize: "20px" }}
          >
            <RiDeleteBin5Line />
          </button>
        </>
      ),
      width: "8%",
    },
  ];
  const ExpandedComponent = ({ data }) => {
    // console.log(data);
    return (
      <>
        <div className={styles.Expandable}>
          <div className={styles.detailList}>
            <div className={styles.detailLeft}>
              <span style={{ display: "flex" }}>
                <p style={{ fontWeight: "700" }}>Order Group :</p>
                <p style={{ color: "green", fontWeight: "500" }}>
                  {data.order_group}
                </p>
              </span>
              <ul>
                <li>
                  <p>Product Name: {data.product_name}</p>
                </li>
                <li>
                  <p>Product Price : ${data.price}</p>
                </li>
                <li>
                  <p>Product Color : {data.color}</p>
                </li>
                <li>
                  <p>Product Size : {data.size}</p>
                </li>
                <li>
                  <p>Quantity : {data.quantity_count}</p>
                </li>
                <li>
                  <p>Total Price : {data.price}</p>
                </li>
              </ul>
            </div>
            <div className={styles.detailRight}>
              <span style={{ display: "flex" }}>
                <p style={{ fontWeight: "700" }}>Customer Name : </p>
                <p style={{ color: "green", fontWeight: "500" }}>{data.name}</p>
              </span>
              <ul>
                <li>
                  <p>Email: {data.email}</p>
                </li>
                <li>
                  <p>Phone : {data.phone}</p>
                </li>
                <li>
                  <p>Address : {data.address}</p>
                </li>
                <li>
                  <p>Address2 : {data.address_full}</p>
                </li>
                <li>
                  <p>Zip Code: {data.zip_code}</p>
                </li>
                <li>
                  <p>Shipping Cost: ${data.shipping_cost}</p>
                </li>
                <ul>
                  <li>
                    <p>Payment Status : {data.paymentStatus}</p>
                  </li>
                  <li>
                    <p>Payment Method : {data.paymentMethod}</p>
                  </li>
                  <li>
                    <p>Shipping Status : {data.shipping_status}</p>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <p>Created : {data.created}</p>
        </div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </>
    );
  };

  // Modal Open For Delete

  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseButton = value => {
    setModalOpen(false);
    setModalShipping(false);
  };
  const [selectedRow, setselectedRow] = useState();

  const handleUpdateClick = (e, id, name, product_name) => {
    e.preventDefault();
    records.map(record => {
      if (record.id === id) {
        return setShippingStatus(record.shipping_status);
      }
    });
    console.log(shippingStatus);
    setselectedRow({ id, name, product_name });
    setModalShipping(true);
  };

  const handleButtonClick = (e, id, name, product_name) => {
    // alert('You clicked the button')
    e.preventDefault();
    setselectedRow({ id, name, product_name });
    setModalOpen(true);
  };

  const handleShippingStatus = async (e, id) => {
    e.preventDefault();
    await updateOrder(
      selectedRow.id,
      { shipping_status: shippingStatus },
      setNotification,
      setUpdateLoading,
      setRecords,
      selectedRow
    );
    setModalShipping(false);
  };
  const handleOrderDelete = async (e, id) => {
    e.preventDefault();
    await deleteOrder(
      selectedRow.id,
      setNotification,
      setUpdateLoading,
      orders,
      setOrders
    );
    setModalOpen(false);
  };

  const handleOnFilterChange = e => {
    setFilter(e.target.value);
    setRecords(
      orders?.map((order, index) => {
        return {
          sn: index + 1,
          id: order._id,
          name: order?.first_name + " " + order?.last_name,
          product_name: order.product.product_name,
          price: order.product.product_price,
          quantity_count: order.product.quantity_count,
          size: order.product.size,
          color: order.product.color,
          country: order.country,
          city: order.city,
          phone: order.phone,
          shipping_status: order.shipping_status,
          order_group: order.order_group,
          totalPrice: order.total_price,
          paymentStatus: order.payment_status,
          paymentMethod: order.payment_method,
          status: order.shipping_status,
          created: new Date(order.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          }),
        };
      })
    );
    if (e.target.value === "") {
      return;
    } else {
      setRecords(prev =>
        prev.filter(record => record.status === e.target.value)
      );
    }
  };

  const handleRefreshClick = async e => {
    const fetchApi = async () => {
      await getOrders(setOrders, setLoading);
    };
    fetchApi();
  };

  const handleFilter = e => {
    const text = e.target.value;
    // Filter according to name and order group
    setRecords(
      orders?.map((order, index) => {
        return {
          sn: index + 1,
          id: order._id,
          name: order?.first_name + " " + order?.last_name,
          product_name: order.product.product_name,
          price: order.product.product_price,
          quantity_count: order.product.quantity_count,
          size: order.product.size,
          color: order.product.color,
          country: order.country,
          city: order.city,
          phone: order.phone,
          shipping_status: order.shipping_status,
          order_group: order.order_group,
          totalPrice: order.total_price,
          paymentStatus: order.payment_status,
          paymentMethod: order.payment_method,
          status: order.shipping_status,
          created: new Date(order.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          }),
        };
      })
    );
    if (text === "") {
      return;
    } else {
      setRecords(prev =>
        prev.filter(
          record =>
            record.name.toLowerCase().includes(text.toLowerCase()) ||
            record.order_group.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  return (
    <div className={styles.Orders}>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => setNotification({ ...notification, open: false })}
            icon={icon}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      {modalShipping &&
        createPortal(
          <Modal
            onClose={handleCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Update Shipping Status</h1>
              <p>
                Update shipping details of Customer:{" "}
                <strong>{selectedRow.name}</strong> <br />
                Product: <strong>{selectedRow.product_name}</strong>
              </p>
              <select
                name='shippingUpdate'
                value={shippingStatus}
                onChange={e => setShippingStatus(e.target.value)}
                className={styles.shippingUpdate}
              >
                {/* <option value=''>Select Status</option> */}
                <option value='open'>Open</option>
                <option value='shipped'>Shipped</option>
                <option value='delivered'>Delivered</option>
              </select>
              <button
                className={styles1.NewArrivalModal}
                onClick={handleShippingStatus}
              >
                {updateLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Update
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {modalOpen &&
        createPortal(
          <Modal
            onClose={handleCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Confirm Delete</h1>
              <p>
                Are you sure to delete shipping details of{" "}
                <strong>{selectedRow.name}</strong> for <br />
                product: <strong>{selectedRow.product_name}</strong>
              </p>
              <button
                className={styles1.ModalButton}
                onClick={handleOrderDelete}
              >
                {updateLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Yes
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {!loading ? (
        <>
          <div className={styles.Topbar}>
            <div className={styles.Heading}>
              <h6>Orders</h6>
            </div>
            <div className={styles.Button}>
              <div onClick={handleRefreshClick}>
                <MdRefresh
                  size={25}
                  color='black'
                />
              </div>
              <div>
                <select
                  name='filter'
                  value={filter}
                  onChange={handleOnFilterChange}
                  className={styles.filter}
                >
                  <option value=''>All Orders</option>
                  <option value='open'>Open</option>
                  <option value='shipped'>Shipped</option>
                  <option value='delivered'>Delivered</option>
                </select>
              </div>
              <div className={styles.search}>
                {/* <IconContext.Provider value={{ size: '30px', color: 'grey' }}>
																<GrFormSearch />
														</IconContext.Provider> */}
                <input
                  type='text'
                  onChange={handleFilter}
                  placeholder='Search By Name, Order Group ..'
                />
              </div>
              {/* <button>Create Order</button> */}
            </div>
          </div>
          <DataTable
            columns={columns}
            data={records}
            pagination
            fixedHeader
            expandableRows
            expandableRowsHideExpander={true}
            expandOnRowClicked={true}
            expandableRowsComponent={ExpandedComponent}
            customStyles={customStyles}
          />
        </>
      ) : (
        <div className={styles.loader}>
          <Loader color='#7e8865' />
        </div>
      )}
    </div>
  );
};

export default Order;
