import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { ShopContext } from "../../context/ShopContext";
// import PRODUCTS from '../../Products'
import { useNavigate } from "react-router-dom";

const Cart = props => {
  const [isCartEmpty, setisCartEmpty] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const {
    addToCart,
    getTotalCartAmount,
    cartItems,
    removeFromCart,
    updateCartItemCount,
    CancelProductFromCart,
  } = useContext(ShopContext);
  const handleCartClose = () => {
    props.setShowCart(false);
  };
  useEffect(() => {
    if (cartItems.length === 0) setisCartEmpty(true);
    if (cartItems) {
      setisCartEmpty(false);
    }
  }, []);

  useEffect(() => {
    setTotalAmount(getTotalCartAmount());
  }, [cartItems, getTotalCartAmount]);

  const handleCheckout = () => {
    props.setShowCart(false);
    navigate("/checkout101");
  };
  const handleViewCart = () => {
    props.setShowCart(false);
    navigate("/cart");
  };
  return (
    <div
      className={styles.modalContainer}
      onClick={e => {
        if (e.target.classList.contains(styles.modalContainer)) {
          handleCartClose();
        }
      }}
    >
      {/* <div className={styles.SideCart}> */}
      <div
        className={styles.CloseButton}
        onClick={handleCartClose}
      >
        <AiOutlineClose />
      </div>
      <h2 id={styles.CartH2}>Your Cart</h2>

      <hr />
      <div className={isCartEmpty ? styles.EmptyCart : styles.hide}>
        <p>Your Cart is Currently Empty</p>
        <button onClick={handleCartClose}>Continue Shopping</button>
      </div>

      {cartItems.map((product, index) => {
        if (cartItems.length !== 0) {
          return (
            <div key={index}>
              <div className={styles.Card}>
                <div
                  className={styles.CardCloseButton}
                  onClick={() => CancelProductFromCart(product?.item)}
                >
                  <AiOutlineClose />
                </div>
                <div className={styles.image}>
                  <img
                    src={`data:image/png;base64,${product?.item?.images[0].data}`}
                    alt='image1'
                  />
                </div>
                <div className={styles.text}>
                  <h4>{product?.item?.product_name}</h4>
                  <p>Color: {product?.item?.color}</p>
                  <p>Size: {product?.item?.size}</p>
                  <p>${product?.item?.selling_price}</p>
                  <div className={styles.countHandler}>
                    <button onClick={() => removeFromCart(product?.item)}>
                      {" "}
                      -{" "}
                    </button>
                    <input
                      value={product?.count}
                      onChange={e =>
                        updateCartItemCount(product, e.target.value)
                      }
                    />
                    <button
                      onClick={e =>
                        updateCartItemCount(product, product?.count + 1)
                      }
                    >
                      {" "}
                      +{" "}
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
      <div className={!isCartEmpty ? styles.TotalAmount : styles.hide}>
        <div className={styles.Amount}>
          <h5>Total</h5>
          <h5>${totalAmount}</h5>
        </div>
        <hr />
      </div>
      <div className={!isCartEmpty ? styles.footer : styles.hide}>
        <p>Shipping, taxes, and discounts will be calculated at checkout</p>
        <button onClick={() => handleCheckout()}>Proceed to Checkout</button>
        <button onClick={() => handleViewCart()}>ViewCart</button>
      </div>
    </div>
  );
};

export default Cart;
