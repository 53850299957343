import { createContext, useState } from "react";

export const CategoryContext = createContext(null);

const setDefaultCategory = () => {
  let category = [];
  return category;
};

export const CategoryContextProvider = props => {
  const [categories, setCategories] = useState(setDefaultCategory());

  const getCategory = async () => {
    return categories;
  };

  const addToCategory = item => {
		return setCategories(item);
  };

  const removeFromCategory = item => {
    const itemId = item._id;
    const newCategory = categories.filter(item => item._id !== itemId);
    setCategories(newCategory);
  };

  const contextValue = {
    categories,
    getCategory,
    setCategories,
    addToCategory,
  };

  return (
    <CategoryContext.Provider value={contextValue}>
      {props.children}
    </CategoryContext.Provider>
  );
};
