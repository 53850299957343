import React, { useContext, useEffect, useState } from "react";
import styles from "./AddProduct.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Loader, MultiSelect, Notification } from "@mantine/core";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { CategoryContext } from "../../../context/CategoryContext";
import { getCategory, getProductFromId } from "../../../api/API_GET";
import { postProduct } from "../../../api/API_POST";
import { updateProduct } from "../../../api/API_UPDATE";

const Update_product = () => {
  const [product_name, setproduct_name] = useState("");
  const product_id = window.location.pathname.split("/")[3];
  const [product_description, setproduct_description] = useState("");
  const [selling_price, setselling_price] = useState("");
  const [crossed_price, setcrossed_price] = useState("");
  const [cost_per_item, setcost_per_item] = useState("");
  const [quantity, setquantity] = useState("");
  const [product_sku, setproduct_sku] = useState("");
  const [product_images, setproduct_images] = useState([]);
  const [uploaded_images, setuploaded_images] = useState([]);
  const [deleted_images, setdeleted_images] = useState([]);
  const [colors, setcolors] = useState([]);
  const [sizes, setsizes] = useState([]);
  const { categories, setCategories } = useContext(CategoryContext);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [product, setProduct] = useState([]);
  const [response, setResponse] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    getCategory(setCategories, setLoading);
    const fetchApi = async () => {
      try {
        await getProductFromId(product_id, setProduct, setLoading);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchApi();
    // getCategory(setCategories, setLoading);
  }, []);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  useEffect(() => {
    console.log("Product:", product);
    setproduct_name(product.product_name);
    setproduct_description(product.description);
    setselling_price(product.selling_price);
    setcrossed_price(product.crossed_price);
    setcost_per_item(product.cost_per_item);
    setquantity(product.quantity);
    setproduct_sku(product.sku);
    setcolors(product.color ? product.color : []);
    setsizes(product.size ? product.size : []);
    setproduct_images(product.images);
    setSelectedOption(product.category);
  }, [product]);

  const Standard = () => {
    const getUploadParams = () => {
      return { url: "https://httpbin.org/post" };
    };

    const handleChangeStatus = ({ meta }, status) => {
      console.log(status, meta);
    };

    const handleSubmit = (files, allFiles) => {
      // console.log(files.map(f => f.meta));
      setuploaded_images(current => [...current, ...files]);
      allFiles.forEach(f => f.remove());
    };

    const DropzoneStyle = {
      dropzone: { minHeight: 200, maxHeight: 200 },
      color: "black",
    };
    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        styles={DropzoneStyle}
        // PreviewComponent={PreviewComponent}
        inputContent='Click here to upload images'
        // styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      />
    );
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const productUpdateHandler = async e => {
    e.preventDefault();
    setUpdateLoading(true);
    console.log(
      "Product:",
      product_name,
      product_description,
      selling_price,
      crossed_price,
      cost_per_item,
      quantity,
      product_sku,
      colors,
      sizes,
      product_images,
      selectedOption
    );

    if (
      product_name === "" ||
      product_description === "" ||
      selling_price === "" ||
      product_sku === "" ||
      sizes.length === 0 ||
      colors.length === 0
    ) {
      setNotification({
        open: true,
        message: "Please fill all the required fields",
        color: "red",
      });
      setUpdateLoading(false);
      return false;
    }
    if (selectedOption === undefined) {
      setSelectedOption(categories[0]._id);
      setNotification({
        open: true,
        message: "Please select category",
        color: "red",
      });
      setUpdateLoading(false);
      return false;
    }

    if (product_images.length === 0 && uploaded_images.length === 0) {
      setNotification({
        open: true,
        message: "Please select product images",
        color: "red",
      });
      setUpdateLoading(false);
      return false;
    }

    if (product_images.length + uploaded_images.length > 4) {
      setNotification({
        open: true,
        message: "Please select maximum 4 images",
        color: "red",
      });
      setUpdateLoading(false);
      return false;
    }

    const data = {
      product_name,
      product_description,
      selling_price,
      crossed_price,
      cost_per_item,
      quantity,
      product_sku,
      colors,
      sizes,
      deleted_images,
      product_images: uploaded_images,
      selectedOption,
    };

    const response = await updateProduct(product_id, data, setResponse);

    if (response.status === 200) {
      setNotification({
        open: true,
        message: response.message,
        color: "green",
      });
      setTimeout(() => {
        window.location.reload();
        setUpdateLoading(false);
      }, 1000);
    }
    if (response.status === 404) {
      console.log("Catch", response);
      setNotification({
        open: true,
        message: response.message,
        color: "red", // Change the color based on your preference
      });
      setUpdateLoading(false);
    }
  };

  const handleImageDelete = (type, image, index) => e => {
    e.preventDefault();
    if (type === "1" && product_images.length > 0) {
      const newImages = [...product_images];
      newImages.splice(index, 1);
      setproduct_images(newImages);
      setdeleted_images(current => [...current, image.id]);
    }
    if (type === "2" && uploaded_images.length > 0) {
      if (uploaded_images.length > 0) {
        const newImages = [...uploaded_images];
        newImages.splice(index, 1);
        setuploaded_images(newImages);
      }
    }
  };

  const handleSellingPriceInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setselling_price(numericValue);
  };

  const handleCrossedPriceInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setcrossed_price(numericValue);
  };

  const handleCostPerItemInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setcost_per_item(numericValue);
  };

  const handleQuantityInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setquantity(numericValue);
  };

  return !loading ? (
    <div className={styles.AddProduct}>
      <div className={styles.Topbar}>
        <div className={styles.Heading}>
          <IconContext.Provider value={{ color: "grey", size: "24px" }}>
            <Link to='/dashboard/Products'>
              <AiOutlineArrowLeft />
            </Link>
          </IconContext.Provider>
          <h6>{product_id ? "Update Product" : "Add Product"}</h6>
        </div>
        <div className={styles.Button}>
          <button
            disabled={updateLoading}
            onClick={productUpdateHandler}
          >
            {updateLoading && (
              <Loader
                color='#7e8865'
                size='sm'
                style={{ marginRight: "10px" }}
              />
            )}
            Update
          </button>
        </div>
      </div>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => {
              setNotification({ ...notification, open: false });
            }}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      <div className={styles.formPart}>
        <form>
          <div className={styles.gridContainer}>
            {/* First Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Product Name<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                placeholder='eg. Bags'
                defaultValue={product_name}
                required
                onChange={e => setproduct_name(e.target.value)}
              ></input>
              <label>
                <h6>
                  Product Description<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <textarea
                type='text'
                rows={3}
                defaultValue={product_description}
                placeholder='eg. Bags'
                required
                onChange={e => setproduct_description(e.target.value)}
              ></textarea>
            </div>
            {/* Second Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Product Images<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <Standard />
            </div>
            {/* OLD IMAGES */}
            <div className={styles.imageItem1}>
              <label>
                <h6>Old Images</h6>
              </label>

              <div className={styles.imageItem2}>
                {/* Fourth Item */}
                {product_images && product_images.length > 0 ? (
                  product_images.map((image, index) => {
                    return (
                      <div className={styles.imageItem}>
                        <div key={index}>
                          <img
                            src={`data:image/png;base64,${image.data}`}
                            alt={image.fileName}
                          />
                        </div>
                        <button
                          className={styles.deleteBtn}
                          onClick={handleImageDelete("1", image, index)}
                        >
                          X
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className={styles.imageItem}>
                      <div>
                        <img
                          src='https://via.placeholder.com/250'
                          alt='placeholder'
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={styles.imageItem1}>
              <label>
                <h6>New Images</h6>
              </label>
              {/* Fourth Item */}
              <div className={styles.imageItem2}>
                {uploaded_images.length > 0 ? (
                  uploaded_images &&
                  uploaded_images.map((image1, index) => {
                    return (
                      <div className={styles.imageItem}>
                        <div key={index}>
                          <img
                            src={URL.createObjectURL(image1.file)} // Use meta.previewUrl to access the previewUrl property
                            alt={image1.meta.name}
                          />
                        </div>
                        <button
                          className={styles.deleteBtn}
                          onClick={handleImageDelete("2", image1, index)}
                        >
                          X
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className={styles.imageItem}>
                      <div>
                        <img
                          src='https://via.placeholder.com/250'
                          alt='placeholder'
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Third Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Selling Price<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                min='0'
                placeholder='eg. 100'
                value={selling_price}
                required
                onChange={handleSellingPriceInput}
              />
              <label style={{ textDecoration: "line-through" }}>
                <h6>Crossed Price</h6>
              </label>
              <input
                type='text'
                min='0'
                value={crossed_price}
                onChange={handleCrossedPriceInput}
              ></input>
              <label>
                <h6>Cost Per Item</h6>
              </label>
              <input
                type='text'
                min='0'
                value={cost_per_item}
                onChange={handleCostPerItemInput}
              ></input>
            </div>
            {/* Fifth Item */}
            <div className={styles.item1}>
              <label>
                <h6>Quantity</h6>
              </label>
              <input
                type='text'
                min='0'
                value={quantity}
                placeholder='eg. 10'
                onChange={handleQuantityInput}
              ></input>
              <label>
                <h6>
                  Product sku<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                min='0'
                defaultValue={product_sku}
                placeholder='eg. 100ABC'
                required
                onChange={e => setproduct_sku(e.target.value)}
              ></input>
            </div>
            {/* Sixth Item */}
            <div className={styles.item2}>
              <MultiSelect
                label='Color'
                required
                data={colors && colors.length > 0 ? colors : []}
                placeholder='Create Products Colors'
                searchable
                creatable
                className={styles.MultiSelect}
                value={colors}
                onChange={setcolors}
                getCreateLabel={query => `+ Create ${query}`}
                onCreate={query => {
                  const item = { value: query, label: query };
                  // Delete old colors and add new colors
                  setcolors(current => [...current, item.value]);
                  return item;
                }}
              />
              <MultiSelect
                label='Size'
                required
                data={sizes && sizes.length > 0 ? sizes : []}
                placeholder='Create Products Sizes'
                searchable
                creatable
                value={sizes}
                onChange={setsizes}
                className={styles.MultiSelect}
                getCreateLabel={query => `+ Create ${query}`}
                onCreate={query => {
                  const item = { value: query, label: query };
                  setsizes(current => [...current, item.value]);
                  return item;
                }}
              />
            </div>
            {/* Seventh Item */}
            <div className={styles.item3}>
              <label>
                <h6>Categories</h6>
              </label>
              <select
                id='framework'
                name='framework'
                value={selectedOption}
                required
                onChange={handleOptionChange}
              >
                {categories.map((category, index) => (
                  <option
                    key={index}
                    value={category._id}
                    disabled={category.disabled}
                  >
                    {category.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.Button}>
            <button
              disabled={updateLoading}
              onClick={productUpdateHandler}
            >
              {updateLoading && (
                <Loader
                  color='#7e8865'
                  size='sm'
                  style={{ marginRight: "10px" }}
                />
              )}
              Update Product
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div className={styles.loader}>
      <Loader color='#7e8865' />
    </div>
  );
};

export default Update_product;
