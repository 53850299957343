import React, { useEffect, useState } from "react";
import styles from "./Category.module.css";
import { BiEdit, BiGridVertical } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import { createPortal } from "react-dom";
import DataTable from "react-data-table-component";
import Modal from "../../components/Modal/Modal";
import { Loader } from "@mantine/core";
import { Notification } from "@mantine/core";
import { MdError } from "react-icons/md";
import { getCategory, getCategoryProducts } from "../../api/API_GET";
import { deleteCategory } from "../../api/API_DELETE";
import { postCategory } from "../../api/API_POST";
import { Link } from "react-router-dom";
const Category = () => {
  const customStyles = {
    // rows: {
    //     style: {
    //         paddingLeft: '10px',
    //         paddingRight: '10px' // override the row height
    //     },
    // },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "2px",
      },
    },
  };
  const icon = <MdError />;

  const [modalCategory, setmodalCategory] = useState();
  const [newCategory, setNewCategory] = useState();
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [products, setProducts] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    getCategory(setCategory, setLoading);
  }, []);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  useEffect(() => {
    setProducts(
      records.map((record, index) => {
        return {
          id: record._id,
          sn: index + 1,
          name: record.product_name,
          createdAt: new Date(record.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        };
      })
    );
  }, [records]);

  //Yes no modal
  const handleButtonClick = value => {
    setModalOpen(false);
  };
  const handleModalOpen = value => {
    setmodalCategory(value);
    setModalOpen(true);
  };
  const [modalOpen, setModalOpen] = useState(false);

  //category
  const [CategorymodalOpen, setCategoryModalOpen] = useState(false);
  const handleCategoryButtonClick = async value => {
    setCategoryModalOpen(false);
  };

  const handleNewSubmitCategory = async e => {
    e.preventDefault();
    await postCategory(
      category,
      newCategory,
      setCategory,
      setDeleteLoading,
      setNotification
    ).finally(() => {
      setDeleteLoading(false);
      setCategoryModalOpen(false);
    });
  };

  const deleteCategoryHandler = async e => {
    e.preventDefault();
    await deleteCategory(
      modalCategory._id,
      category,
      setCategory,
      setDeleteLoading,
      setNotification
    ).finally(() => {
      setDeleteLoading(false);
      setModalOpen(false);
    });
  };

  const getProductsRelatedToCategory = async id => {
    setClicked(true);
    await getCategoryProducts(
      id,
      setRecords,
      setProductLoading,
      setNotification
    );
  };

  const columns = [
    {
      name: "#",
      selector: row => row.sn,
      sortable: true,
      width: "5%",
    },
    {
      name: "Product Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Created",
      selector: row => row.createdAt,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      cell: row => (
        <>
          <Link to={`/dashboard/edit_product/${row.id}`}>
            <button
              className='btn btn-outline btn-xs'
              // onClick={(e) => handleButtonClick(e, row.id)}
              style={{ color: "green", fontSize: "20px" }}
            >
              <BiEdit />
            </button>
          </Link>
        </>
      ),
      minWidth: "20%",
    },
  ];

  return (
    <div className={styles.Category}>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => setNotification({ ...notification, open: false })}
            icon={icon}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}

      {modalOpen &&
        createPortal(
          <Modal
            onClose={handleButtonClick}
            onSubmit={handleButtonClick}
            onCancel={handleButtonClick}
            widthProp='30%'
          >
            <div className={styles.ModalDiv}>
              <h1>Confirm Delete</h1>
              <p style={{ color: "red" }}>
                All products related to this category will be deleted. Please
                update category of the products before deleting.
              </p>
              <p>
                Are you sure to delete category{" "}
                <strong>{modalCategory.title} </strong>?
              </p>
              <button
                className={styles.ModalButton}
                disabled={deleteLoading}
                onClick={deleteCategoryHandler}
              >
                {deleteLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Yes
              </button>
            </div>
          </Modal>,
          document.body
        )}
      {CategorymodalOpen &&
        createPortal(
          <Modal
            onClose={handleCategoryButtonClick}
            onSubmit={handleCategoryButtonClick}
            onCancel={handleCategoryButtonClick}
            widthProp='40%'
          >
            <div className={styles.ModalDiv}>
              <h4>Add Category</h4>
              <form>
                <div className={styles.form}>
                  <label>
                    <h6>
                      Category name:<span style={{ color: "red" }}>*</span>
                    </h6>
                  </label>
                  <input
                    type='text'
                    placeholder='Category Name'
                    onChange={e => setNewCategory(e.target.value)}
                  />
                </div>
                <button
                  className={styles.ModalSubmitButton}
                  onClick={handleNewSubmitCategory}
                >
                  {deleteLoading && (
                    <Loader
                      color='#7e8865'
                      size='sm'
                    />
                  )}
                  Save
                </button>
              </form>
            </div>
          </Modal>,
          document.body
        )}
      {loading ? (
        <div className={styles.loader}>
          <Loader color='#7e8865' />
        </div>
      ) : (
        <>
          <div className={styles.Heading}>
            <h6>Store Categories</h6>
            <button onClick={() => setCategoryModalOpen(true)}>
              Add Category
            </button>
          </div>
          <div className={styles.table}>
            <div className={styles.tableHeading}>
              <div className={styles.SN}>#</div>
              <div className={styles.Name}>Name</div>
              <div className={styles.Actions}>Actions</div>
            </div>
            {category.length === 0 ? (
              <div className={styles.tableBody}>
                <div className={styles.SN}>
                  <BiGridVertical />
                </div>
                <div className={styles.Name}>No Category</div>
                <div className={styles.Actions}></div>
              </div>
            ) : (
              category.map((category, index) => {
                return (
                  <div
                    className={styles.tableBody}
                    key={index}
                  >
                    <div className={styles.SN}>
                      <BiGridVertical />
                    </div>
                    <div className={styles.Name}>{category.title}</div>
                    <div className={styles.Actions}>
                      <IconContext.Provider
                        value={{ color: "red", size: "20px" }}
                      >
                        <button
                          className='btn btn-success btn-sm me-5'
                          onClick={() =>
                            getProductsRelatedToCategory(category._id)
                          }
                        >
                          View products
                        </button>
                        <RiDeleteBin5Line
                          onClick={() => handleModalOpen(category)}
                        />
                      </IconContext.Provider>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {productLoading ? (
            <div className={styles.loader2}>
              <Loader color='#7e8865' />
            </div>
          ) : (
            <>
              <div className={styles.Heading}>
                <h6>Products</h6>
              </div>
              <DataTable
                columns={columns}
                data={products}
                pagination
                fixedHeader
                noDataComponent={
                  !clicked ? (
                    <div className='p-4'>
                      Select View Products to show products of the Category!
                    </div>
                  ) : (
                    <div className='p-4'>No products in this Category!</div>
                  )
                }
                customStyles={customStyles}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Category;
