import React, { useContext, useEffect, useState } from "react";
import styles from "./AddProduct.module.css";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Loader, MultiSelect, Notification } from "@mantine/core";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { CategoryContext } from "../../../context/CategoryContext";
import { getCategory } from "../../../api/API_GET";
import { postProduct } from "../../../api/API_POST";

const AddProduct = () => {
  const [product_name, setproduct_name] = useState("");
  const [product_description, setproduct_description] = useState("");
  const [selling_price, setselling_price] = useState("");
  const [crossed_price, setcrossed_price] = useState("");
  const [cost_per_item, setcost_per_item] = useState("");
  const [quantity, setquantity] = useState("");
  const [product_sku, setproduct_sku] = useState("");
  const [product_images, setproduct_images] = useState([]);
  const [colors, setcolors] = useState([]);
  const [sizes, setsizes] = useState([]);
  const { categories, setCategories } = useContext(CategoryContext);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [response, setResponse] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    getCategory(setCategories, setLoading);
  }, [setCategories]);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const Standard = () => {
    const getUploadParams = () => {
      return { url: "https://httpbin.org/post" };
    };

    const handleChangeStatus = ({ meta }, status) => {
      console.log(status, meta);
    };

    const handleSubmit = (files, allFiles) => {
      // console.log(files.map(f => f.meta));
      setproduct_images(current => [...current, ...files]);
      allFiles.forEach(f => f.remove());
    };

    const DropzoneStyle = {
      dropzone: { minHeight: 200, maxHeight: 200 },
      color: "black",
    };
    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        styles={DropzoneStyle}
        // PreviewComponent={PreviewComponent}
        inputContent='Click here to upload images'
        // styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      />
    );
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const productAddHandler = async e => {
    e.preventDefault();
    setAddLoading(true);
    if (
      product_name === "" ||
      product_description === "" ||
      selling_price === "" ||
      product_sku === "" ||
      colors.length === 0 ||
      sizes.length === 0
    ) {
      setNotification({
        open: true,
        message: "Please fill all the required fields",
        color: "red",
      });
      setAddLoading(false);
      return false;
    }
    if (selectedOption === undefined) {
      setSelectedOption(categories[0]._id);
      setNotification({
        open: true,
        message: "Please select category",
        color: "red",
      });
      setAddLoading(false);
      return false;
    }

    if (product_images.length === 0) {
      setNotification({
        open: true,
        message: "Please select product images",
        color: "red",
      });
      setAddLoading(false);
      return false;
    }

    if (product_images.length > 4) {
      setNotification({
        open: true,
        message: "Please select only 4 images",
        color: "red",
      });
      setAddLoading(false);
      return false;
    }

    const response = await postProduct(
      product_name,
      product_description,
      selling_price,
      crossed_price,
      cost_per_item,
      quantity,
      product_sku,
      colors,
      product_images,
      sizes,
      selectedOption,
      setResponse
    );

    if (response.status === 200) {
      setNotification({
        open: true,
        message: response.message,
        color: "green",
      });
      setTimeout(() => {
        window.location.reload();
        setAddLoading(false);
      }, 1000);
    } else if (response.status === 404) {
      console.log("Catch", response);
      setAddLoading(false);
      setNotification({
        open: true,
        message: response.message,
        color: "red", // Change the color based on your preference
      });
    } else {
      setNotification({
        open: true,
        message: response.message,
        color: "red", // Change the color based on your preference
      });
    }
  };

  const handleImageDelete = index => e => {
    e.preventDefault();
    const newImages = [...product_images];
    newImages.splice(index, 1);
    setproduct_images(newImages);
  };

  const handleSellingPriceInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setselling_price(numericValue);
  };

  const handleCrossedPriceInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setcrossed_price(numericValue);
  };

  const handleCostPerItemInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setcost_per_item(numericValue);
  };

  const handleQuantityInput = e => {
    // Ensure that the input only contains numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    // Update the state with the sanitized numeric value
    setquantity(numericValue);
  };

  return !loading ? (
    <div className={styles.AddProduct}>
      <div className={styles.Topbar}>
        <div className={styles.Heading}>
          <IconContext.Provider value={{ color: "grey", size: "24px" }}>
            <Link to='/dashboard/Products'>
              <AiOutlineArrowLeft />
            </Link>
          </IconContext.Provider>
          <h6>Add Product</h6>
        </div>
        <div className={styles.Button}>
          <button onClick={productAddHandler}>Add</button>
        </div>
      </div>
      {notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => {
              setNotification({ ...notification, open: false });
            }}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      <div className={styles.formPart}>
        <form>
          <div className={styles.gridContainer}>
            {/* First Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Product Name<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                placeholder='eg. Bags'
                required
                onChange={e => setproduct_name(e.target.value)}
              ></input>
              <label>
                <h6>
                  Product Description<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <textarea
                type='text'
                rows={3}
                placeholder='eg. Bags'
                required
                onChange={e => setproduct_description(e.target.value)}
              ></textarea>
            </div>
            {/* Second Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Product Images<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <Standard />
            </div>
            {/* Third Item */}
            <div className={styles.item1}>
              <label>
                <h6>
                  Selling Price<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                min='0'
                placeholder='eg. 100'
                value={selling_price}
                required
                onChange={handleSellingPriceInput}
              ></input>
              <label style={{ textDecoration: "line-through" }}>
                <h6>Crossed Price</h6>
              </label>
              <input
                type='text'
                min='0'
                value={crossed_price}
                onChange={handleCrossedPriceInput}
              ></input>
              <label>
                <h6>Cost Per Item</h6>
              </label>
              <input
                type='text'
                min='0'
                value={cost_per_item}
                onChange={handleCostPerItemInput}
              ></input>
            </div>
            {/* Fourth Item */}
            <div className={styles.imageItem1}>
              <label>
                <h6>Image Preview</h6>
              </label>

              <div className={styles.imageItem2}>
                {product_images.length > 0 ? (
                  product_images &&
                  product_images.map((image, index) => {
                    console.log(image);
                    return (
                      <div className={styles.imageItem}>
                        <div key={index}>
                          <img
                            src={URL.createObjectURL(image.file)} // Use meta.previewUrl to access the previewUrl property
                            alt={image.meta.name}
                          />
                        </div>
                        <button
                          className={styles.deleteBtn}
                          onClick={handleImageDelete(index)}
                        >
                          X
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className={styles.imageItem}>
                      <div>
                        <img
                          src='https://via.placeholder.com/250'
                          alt='placeholder'
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Fifth Item */}
            <div className={styles.item1}>
              <label>
                <h6>Quantity</h6>
              </label>
              <input
                type='text'
                min='0'
                placeholder='eg. 10'
                required
                value={quantity}
                onChange={handleQuantityInput}
              ></input>
              <label>
                <h6>
                  Product sku<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <input
                type='text'
                min='0'
                placeholder='eg. 100ABC'
                required
                onChange={e => setproduct_sku(e.target.value)}
              ></input>
            </div>
            {/* Sixth Item */}
            <div className={styles.item2}>
              <MultiSelect
                label='Color'
                data={colors}
                placeholder='Create Products Colors'
                searchable
                creatable
                required
                className={styles.MultiSelect}
                getCreateLabel={query => `+ Create ${query}`}
                onCreate={query => {
                  const item = { value: query, label: query };
                  setcolors(current => [...current, item.value]);
                  return item;
                }}
              />
              <MultiSelect
                label='Size'
                data={sizes}
                placeholder='Create Products Sizes'
                searchable
                creatable
                required
                className={styles.MultiSelect}
                getCreateLabel={query => `+ Create ${query}`}
                onCreate={query => {
                  const item = { value: query, label: query };
                  setsizes(current => [...current, item.value]);
                  return item;
                }}
              />
            </div>
            {/* Seventh Item */}
            <div className={styles.item3}>
              <label>
                <h6>
                  Categories<span style={{ color: "red" }}>*</span>
                </h6>
              </label>
              <select
                id='framework'
                name='framework'
                value={selectedOption}
                required
                onChange={handleOptionChange}
              >
                {categories.map((category, index) => (
                  <option
                    key={index}
                    value={category._id}
                    disabled={category.disabled}
                  >
                    {category.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.Button}>
            <button
              disabled={addLoading}
              onClick={productAddHandler}
            >
              {addLoading && (
                <Loader
                  style={{ marginRight: "10px" }}
                  size='sm'
                  color='#7e8865'
                />
              )}
              Add Product
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div className={styles.loader}>
      <Loader color='#7e8865' />
    </div>
  );
};

export default AddProduct;
