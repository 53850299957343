// Define constants
const API_URL = "https://groovy-lpys.onrender.com/api/";
const API_VERSION = "v1";

// Create API_AUTH URL
const API_AUTH = `${API_URL}${API_VERSION}/auth`;
const API_PRODUCT = `${API_URL}${API_VERSION}/product`;
const API_CATEGORY = `${API_URL}${API_VERSION}/category`;
const API_ORDER = `${API_URL}${API_VERSION}/order`;
const API_NEW_ARRIVAL = `${API_URL}${API_VERSION}/newarrivals`;
const API_BANNER = `${API_URL}${API_VERSION}/banner`;

// Export constants
export {
  API_URL,
  API_VERSION,
  API_AUTH,
  API_CATEGORY,
  API_ORDER,
  API_PRODUCT,
  API_NEW_ARRIVAL,
	API_BANNER,
};
