import React, { useContext, useEffect, useState } from "react";
import { AiOutlineShoppingCart, AiOutlineSearch } from "react-icons/ai";
import { FaStarHalf, FaStar } from "react-icons/fa";
import styles from "./Card.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ShopContext } from "../../context/ShopContext";
import { createPortal } from "react-dom";
import Modal from "../Modal/Modal";
import Slider from "../Slider/Slider";
import Button from "../Button/Button";

const Card = props => {
  const { item, setShowCart } = props;
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Generate a random rating between 4 and 5 to determine the number of half stars
  const randomHalfStars = Math.floor(Math.random() * 2); // 0 or 1
  // Generate an array of stars and half stars
  const starArray = Array.from({ length: 4 }, (_, index) => (
    <FaStar
      key={index}
      style={{ fontSize: "18px" }}
    />
  ));

  // Add half stars
  for (let i = 0; i < randomHalfStars; i++) {
    starArray.push(
      <FaStarHalf
        key={`half-${i}`}
        style={{ fontSize: "18px" }}
      />
    );
  }

  // If needed, add remaining full stars to reach a total of 5
  while (starArray.length < 5) {
    starArray.push(
      <FaStar
        key={`empty-${starArray.length}`}
        style={{ fontSize: "18px" }}
      />
    );
  }
  // console.log(windowWidth);
  const location = useLocation();
  const locationString = location.pathname.slice(1);
  // eslint-disable-next-line
  const [shopStyle, setShopStyle] = useState(false);

  useEffect(() => {
    if (locationString.includes("shop") && windowWidth < 800) {
      setShopStyle(true);
    } else {
      setShopStyle(false);
    }
  }, [locationString, windowWidth]);

  const { addToCart, cartItems, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);
  const navigate = useNavigate();

  const handleImageOnClick = id => {
    navigate(`/product/${id}`);
  };
  const handleAddToCart = item => {
    const c = 1;
    // Check color and size are selected
    console.log(c);
    const {
      _id,
      product_name,
      selling_price,
      crossed_price,
      description,
      images,
    } = item;
    if (c === 1) {
      console.log("c is 1");
      const items = {
        _id,
        product_name,
        selling_price,
        crossed_price,
        description,
        images,
        color: item?.color[0],
        size: item?.size[0],
      };
      addToCart(items, c);
      setShowCart(true);
      return;
    }
    const items = {
      _id,
      product_name,
      selling_price,
      crossed_price,
      description,
      images,
      color: item?.color[0],
      size: item?.size[0],
    };
    addToCart(items, c);
    setShowCart(true);
  };
  const addToCartModal = () => {
    addToCart(item);
  };

  const slides = [
    {
      url: "http://localhost:3000/static/media/product4.c421b0c58a52bd3dd311.jpg",
      title: "Product1",
    },
    {
      url: "http://localhost:3000/static/media/product5.064fac56fff16c1c6959.jpg",
      title: "Product2",
    },
    {
      url: "http://localhost:3000/static/media/product6.4d937b723d9f4049579b.png",
      title: "Product3",
    },
  ];
  const handleButtonClick = value => {
    setModalOpen(false);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const containerSlides = {
    width: "520px",
    height: "450px",
    margin: "0",
  };
  // const handleAddToCartPage = (id) => {
  //     navigate('/cart')
  // }
  const handleSearchClick = () => {
    // navigate('/search')
  };
  return (
    <>
      <div className={styles.card}>
        {modalOpen &&
          createPortal(
            <Modal
              onClose={handleButtonClick}
              onSubmit={handleButtonClick}
              onCancel={handleButtonClick}
            >
              <div className={styles.modalDiv}>
                <div className={styles.ModalImageDiv}>
                  <div style={containerSlides}>
                    <Slider
                      slides={slides}
                      id={item._id}
                    />
                  </div>
                </div>
                <div className={styles.ModalTextDiv}>
                  <h3>{item.product_name}</h3>
                  <p>
                    <span>Price:</span> <span>${item.selling_price}</span>{" "}
                    <span
                      style={{
                        textDecoration: " line-through",
                        fontSize: "18px",
                      }}
                    >
                      ${item.crossed_price}
                    </span>
                  </p>
                  <div className={styles.gridItem}>
                    <span>Color:</span>
                    <div className={styles.ColorRow}>
                      {item.color.map(item => (
                        <div
                          key={item}
                          className={styles.colorDiv}
                          style={{ backgroundColor: item }}
                        ></div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.gridItem}>
                    <span>Size:</span>
                    <div className={styles.SizeRow}>
                      {item.size.map(item => (
                        <div
                          key={item}
                          className={styles.sizeDiv}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <p>{item.description}</p>
                  <div className={styles.countHandler}>
                    <button onClick={() => removeFromCart(item._id)}>
                      {" "}
                      -{" "}
                    </button>
                    <input
                      value={cartItems[item.id]}
                      onChange={e =>
                        updateCartItemCount(Number(e.target.value), item._id)
                      }
                    />
                    <button onClick={() => addToCartModal(item)}> + </button>
                  </div>
                  {/* <button onClick={() => handleAddToCart(item.id)}>Add to Cart</button> */}
                  <Button
                    name='Add to Cart'
                    widthProp='50%'
                    action='/cart'
                  ></Button>
                  {/* {<p>Input The number of items</p>} */}
                  {/* action={cartItems[item.id] > 0 ? '/cart' : ''} */}
                  {/* <Button name="Add to wishlist" widthProp="50%"></Button> */}
                  <button
                    className={styles.ProductButton}
                    onClick={() => handleImageOnClick(item._id)}
                  >
                    {" "}
                    View Product &rarr;
                  </button>
                </div>
              </div>
            </Modal>,
            document.body
          )}

        <div
          className={
            shopStyle
              ? `${styles.ShopStyle} ${styles.cardImage}`
              : styles.cardImage
          }
        >
          {/* {item?.images?.map((image, index) => {
            if (index !== 0) return;
          return ( */}
          <img
            // key={index}
            src={`data:image/png;base64,${item?.images[0].data}`}
            alt={item.product_name}
          />
          {/* );
          })} */}
          <div
            className={styles.overlay}
            onClick={() => handleImageOnClick(item._id)}
          ></div>
          <div className={styles.text}>
            <div
              className={styles.Icons}
              onClick={() => handleAddToCart(item)}
            >
              <AiOutlineShoppingCart />
            </div>
            <div className={styles.v1}></div>
            {/* <div className={styles.Icons}>
                            <AiOutlineStar />
                        </div> */}
            {/* <div className={styles.v1}></div> */}
            <div className={styles.Icons}>
              <AiOutlineSearch onClick={() => handleSearchClick()} />
            </div>
          </div>
        </div>
        <div className={styles.cardBody}>
          <h2>{item.product_name}</h2>
          <p>
            {/* Create rating with random number between 4 to 5 */}
            {starArray.map((star, index) => (
              <React.Fragment key={index}>{star}</React.Fragment>
            ))}
            {/* <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar /> */}
          </p>
          {item.crossed_price === "0" || !item.crossed_price ? null : (
            <p className={styles.CrossPrice}>${item.crossed_price}</p>
          )}
          <p>${item.selling_price}</p>
          <div className={styles.CardAction}>
            <button onClick={() => handleAddToCart(item)}>
              {" "}
              <AiOutlineShoppingCart />
            </button>
            <button onClick={() => handleSearchClick()}>
              <AiOutlineSearch />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
