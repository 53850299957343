import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { AiFillHome, AiFillGift } from "react-icons/ai";
import { IconContext } from "react-icons";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { RiShoppingCartFill } from "react-icons/ri";
import { FaBookmark, FaLayerGroup } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { logoutAdmin } from "../../api/API_GET";

const SideBar = () => {
  const location = useLocation();
  let locationString = location.pathname.slice(1);

  const [pathname, setpathname] = useState(location.pathname.slice(11));
  const shouldShowNavbar =
    locationString.includes("dashboard") &&
    !locationString.includes("AccessDenied") &&
    !locationString.includes("login");
  useEffect(() => {
    setpathname(location.pathname.slice(11));
  }, [location]);

  const handleLogout = async () => {
    await logoutAdmin();
  };

  return (
    <>
      {shouldShowNavbar && (
        <div className={styles.FixedSideBar}>
          <div className={styles.UserHeader}>
            <h2>Admin</h2>
          </div>
          <hr />
          <div className={styles.LinksBody}>
            <h6>Main Links</h6>
            <IconContext.Provider value={{ size: "20px", color: "#03383c" }}>
              <Link to='/dashboard/'>
                <div
                  className={
                    pathname === ""
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <AiFillHome />
                  <h4>Home</h4>
                </div>
              </Link>
              <Link to='/dashboard/Category'>
                <div
                  className={
                    pathname === "Category"
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <BiSolidCategoryAlt />
                  <h4>Category</h4>
                </div>
              </Link>
              <Link to='/dashboard/Products'>
                <div
                  className={
                    pathname === "Products"
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <AiFillGift />
                  <h4>Products</h4>
                </div>
              </Link>
              <Link to='/dashboard/Order'>
                <div
                  className={
                    pathname === "Order"
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <RiShoppingCartFill />
                  <h4>Orders</h4>
                </div>
              </Link>
              <Link to='/dashboard/banner'>
                <div
                  className={
                    pathname === "banner"
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <FaLayerGroup />
                  <h4>Banners</h4>
                </div>
              </Link>
              <Link to='/dashboard/new_arrivals'>
                <div
                  className={
                    pathname === "new_arrivals"
                      ? `${styles.DashboardItems} ${styles.active}`
                      : styles.DashboardItems
                  }
                >
                  <FaBookmark />
                  <h4>New Arrivals</h4>
                </div>
              </Link>

              <div className={styles.logoutBtn}>
                <div className={styles.DashboardItems}>
                  <MdLogout />
                  <h4 onClick={handleLogout}>Logout</h4>
                </div>
              </div>
            </IconContext.Provider>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
