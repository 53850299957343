import React, { useEffect, useState } from "react";
import styles from "./Arrival.module.css";
import { createPortal } from "react-dom";
import styles1 from "./Products/tableOwn.module.css";
import { Loader, Notification } from "@mantine/core";
import DataTable from "react-data-table-component";
import { getNewArrivals } from "../../api/API_GET";
import { RiDeleteBin5Line } from "react-icons/ri";
import Modal from "../../components/Modal/Modal";
import { deleteNewArrival } from "../../api/API_DELETE";
import { MdError } from "react-icons/md";

export default function NewArrivals() {
	const icon = <MdError />;
  const [newArrivals, setNewArrivals] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(false);
	const [notification, setNotification] = useState({
    open: false,
    message: "",
    color: "red", // Change the color based on your preference
  });

  useEffect(() => {
    getNewArrivals(setNewArrivals, setLoading);
  }, []);

  useEffect(() => {
    setRecords(
      newArrivals.map((item, index) => ({
        id: item._id,
        name: item.title,
        product_id: item.product_id,
        sn: index + 1,
      }))
    );
  }, [newArrivals, setNewArrivals]);

	useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification({
          ...notification,
          open: false,
        });
      }, 3000);
    }
  }, [notification]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "2px",
      },
    },
  };

  const handleCloseButton = () => {
    setModalOpen(false);
  };

  const handleButtonClick = (e, id, name) => {
    e.preventDefault();
    setSelectedRow({ id, name });
    setModalOpen(true);
  };

  const handleProductDelete = async e => {
    e.preventDefault();
    setDeleteLoading(true);
    await deleteNewArrival(
      selectedRow.id,
			setNotification,
      newArrivals,
      setNewArrivals
    );
    setModalOpen(false);
    setDeleteLoading(false);
  };

  const columns = [
    {
      name: "#",
      selector: row => row.sn,
      sortable: true,
      width: "5%",
    },
    {
      name: "Product Name",
      selector: row => row.name,
      sortable: true,
      width: "20%",
    },
    {
      name: "Product ID",
      selector: row => row.product_id,
      sortable: true,
      width: "20%",
    },
    {
      name: "Action",
      button: true,
      cell: row => (
        <>
          <button
            className='btn btn-outline btn-xs'
            onClick={e => handleButtonClick(e, row.id, row.name)}
            style={{ color: "red", fontSize: "20px" }}
          >
            <RiDeleteBin5Line />
          </button>
        </>
      ),
      minWidth: "20%",
    },
  ];
  return (
    <div className={styles.Category}>
      {modalOpen &&
        createPortal(
          <Modal
            onClose={handleCloseButton}
            widthProp='30%'
          >
            <div className={styles1.ModalDiv}>
              <h1>Confirm Delete</h1>
              <p>
                Are you sure to delete <strong>{selectedRow.name}</strong>?
              </p>
              <button
                disabled={deleteLoading}
                className={styles1.ModalButton}
                onClick={handleProductDelete}
              >
                {deleteLoading && (
                  <Loader
                    color='#7e8865'
                    size='sm'
                  />
                )}
                Delete
              </button>
            </div>
          </Modal>,
          document.body
        )}
			{notification.open && (
        <div className={styles.notification}>
          <Notification
            color={notification.color}
            title={notification.message}
            onClose={() => setNotification({ ...notification, open: false })}
            icon={icon}
            styles={{
              title: {
                fontWeight: "bold",
                fontSize: "16px",
              },
              body: {
                color: "white",
                padding: "20px",
              },
            }}
          ></Notification>
        </div>
      )}
      {!loading ? (
        <>
          <div className={styles.Heading}>
            <h6>New Arrivals</h6>
          </div>
          <DataTable
            columns={columns}
            data={records}
            pagination
            fixedHeader
            customStyles={customStyles}
          />
        </>
      ) : (
        <div className={styles.loader}>
          <Loader color='#7e8865' />
        </div>
      )}
      {/* <TableOwn modalOpenTable={modalOpenTable} /> */}
    </div>
  );
}
